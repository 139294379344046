import "./PageOne.css";
import { toast } from "../../helpers";
import { useEffect, useState } from "react";
import { useCreateRecipientMutation, useGetRecipientsQuery } from "../../store/recipient";
import List from "./List";
import { setRecipientData } from "../../store/Slices/transferSlice";
import { useDispatch } from "react-redux";
import { setTransactionData } from "../../store/Slices/transferSlice";
import AddRecipientModal from "../modal/RecipientModel";
import TransferForm from "../controllers/TransferForm";

function MyComponen2t({ onClick, onButtonClick, data, isLoading, error }) {
    const [searchTerm, setSearchTerm] = useState("");

    const filteredRecipient =
        data?.recipients?.filter(recipient =>
            recipient.firstName.toLowerCase().includes(searchTerm.toLowerCase())
        ) || [];

    return (
        <div className="flex mt-1 h-[433px] flex-col pb-6 pl-6 bg-white rounded-xl border border-solid border-[#d4d4d8] max-w-[469px]">
            <div>
                {/* top search and add */}
                <div className="flex gap-5  w-full max-md:gap-0 justify-between">
                    <div className="flex flex-col w-full max-md:ml-0  justify-between">
                        <div className="flex z-10 flex-col self-stretch my-auto justify-between">
                            <div className="text-2xl text-left font-semibold tracking-wide text-[#0f172a]">
                                Select a recipient{" "}
                            </div>
                            <div className="flex gap-4 mt-4 text-sm leading-6 ">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search all banks"
                                        className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        value={searchTerm}
                                        onChange={e => setSearchTerm(e.target.value)} // Update searchTerm state
                                    />
                                    <div className="absolute left-3 top-3 text-gray-500">
                                        <svg
                                            className="w-5 h-5"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M21 21l-4.35-4.35m0 0A7.5 7.5 0 1110.5 3.5a7.5 7.5 0 016.65 13.15l4.35 4.35z"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    className="flex cursor-pointer text-secondary  justify-center py-1.5 px-10 items-center   font-semibold text-white whitespace-nowrap bg-[#4f46e5] rounded-md shadow-sm"
                                    onClick={onClick}
                                >
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e64266c576d6fa4c900c9f01f26bb434b3284fcc53c3589ec517223282004d56?apiKey=786e42c6526d4ad4bad3df542e370598&"
                                        className="shrink-0 my-auto w-4 aspect-square"
                                    />
                                    <div>Add</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col ml-5 w-[22%] max-md:ml-0 max-md:w-full">
                        <div className="shrink-0 h-[116px] w-[116px]" />
                    </div>
                </div>
            </div>
            <List isLoading={isLoading} error={error} data={filteredRecipient} />
            <div
                className="justify-center items-center -ml-4 cursor-pointer mr-2 px-4 py-2.5 mt-6 text-sm font-semibold leading-6 text-secondary whitespace-nowrap bg-[#4f46e5] rounded-md shadow-sm"
                onClick={onButtonClick}
            >
                Send
            </div>
        </div>
    );
}
function PageOne({ onButtonClick }) {
    const [selectedOption, setSelected] = useState("USD");
    const [enteredValue, setEntered] = useState(1);
    const [selectedCurrent, setCurrency] = useState("1.184192");
    const [oppositeOption, setOption] = useState("KES");
    const [findedValue, setFinded] = useState(0.23);
    const [charge, setCharge] = useState(0);
    const { data, error, isLoading, refetch } = useGetRecipientsQuery();

    const calculate = (selected, opposite, entered) => {
        fetch(`https://api.faremit.com/api/paircode/${selected}/${opposite}/${entered}`)
            .then(res => res.json())
            .then(res => {
                const rate = res.conversion_rate.toFixed();
                const conversionResult = res.conversion_result;
                console.log(res);
                setCurrency(`${rate}`);

                // Calculate charge based on the selected currency
                let charge = selected === "USD" ? 4 : 2;
                setCharge(charge);

                // Set converted value from the server response
                setFinded(conversionResult.toFixed(2));
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                // Handle the error appropriately in your application
            });
    };

    useEffect(() => {
        calculate(selectedOption, oppositeOption, enteredValue);
    }, [selectedOption, oppositeOption, enteredValue]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
    console.log(isModalOpen);
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <div className="flex items-center justify-center px-4 py-8 -mt-10 gap-5">
                <TransferForm button={false} />
                <div className="flex flex-col ml-5 max-md:ml-0 max-md:w-full">
                    <MyComponen2t
                        error={error}
                        isLoading={isLoading}
                        data={data}
                        onClick={openModal}
                        onButtonClick={onButtonClick}
                    />
                </div>
            </div>
            <AddRecipientModal
                refetch={refetch}
                isOpen={isModalOpen}
                closeModal={closeModal}
                close={isModalOpen}
            />
        </div>
    );
}
export default PageOne;
