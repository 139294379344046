import { Rootapi } from "..";

const Transactions = Rootapi.injectEndpoints({
    endpoints: builder => ({
        getTransactions: builder.query({
            query: () => ({
                url: "transaction/getUserTransactions",
                method: "GET"
            })
        })
    })
});

export const { useGetTransactionsQuery } = Transactions;
