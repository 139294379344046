import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

export const Auth = ({ children }) => {
    const token = localStorage.getItem("token");
    // console.log("Token from storage:", token);
    if (!token || isTokenExpired(token)) {
        return <Navigate to="/" />;
    }
    return children;
};

export const Guest = ({ children }) => {
    const token = localStorage.getItem("token");
    if (token && !isTokenExpired(token)) {
        return <Navigate to="/" />;
    }
    return children;
};

function isTokenExpired(token) {
    const decodedToken = jwt_decode(token);
    return Date.now() >= decodedToken.exp * 1000;
}
