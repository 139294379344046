import React, { useState } from "react";
import Flag from "react-world-flags";
function CountryCard({ country, flag }) {
    return (
        <div className="flex flex-col justify-center p-3 bg-white rounded-xl shadow-sm">
            {" "}
            <div className="flex gap-4">
                {" "}
                <div className="flex justify-center items-center">
                    {" "}
                    <img
                        src={flag}
                        alt={`Flag of ${country}`}
                        className="w-11 rounded-md aspect-square"
                    />{" "}
                </div>{" "}
                <div className="my-auto text-xl leading-8 text-slate-900">{country}</div>{" "}
            </div>{" "}
        </div>
    );
}
function CountriesCom() {
    const countries = [
        {
            name: "Algeria",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/9f9d16a716ce68ae67a61b018a87cee0585b008bbd12679c29bbbde7137bec03?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Angola",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/dd3648553ee58164d64e30563965d6cfb839153286965a2f7cd564a2cba6e4c1?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Benin",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/ab0537402e1a710e20481b332946afaaa5eb518bc0455f6ed3eebb8071a96f7e?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Botswana",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/67eb415b77a1833ee481c15207a80ff205456efd3d721028aea95a3b37c6ba31?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Burkina Faso",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/e5c77d4e3fe38955c1e0e8cb9d350943008464b67f14fe18029278d1f07d3326?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Burundi",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/f04a1857a5e8a02c45b14cd13ba2d7623feccb563437c8d090495cb9138c4553?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Cameroon",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/fa8b62d353cbb04322e77adb9930cbae835ea6f721da0452efbbc6d49bce7673?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Cape Verde",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/d502eae8792134d53efb67cb3772bdf67690760370efabc16a30fb46ac99c24a?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Central African Republic",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/ed65502d571b296572a170aee74f630b2afdd425dea789d0f94fa7ffff921f89?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Chad",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/bb7244aab1da88b748c1bfac89b78f3863591b1c128fd62bd9d0750525070656?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Comoros",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/00d75d6640023c50df4998c260f5604ae0206a46437a96bb75637f81f9f30fea?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "DR Congo",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/54b391bb8808c1bb5a3de223368491ccfe33778112b2623707c1165b0518873e?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Djibouti",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/e6b907db63c3e2d0d1a6be54ceb0c486d24debed5bc62831ee2bce32d55fa042?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Egypt",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/4d18de10efd5cc870f4646d66707e671621cb3138b4736cfbc0ef1b951de7a73?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Eritrea",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/7e1e74c3d21e6ac58476cdf5df07f8cd1f04e58d4f8775aeef8166ba80d4aab2?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Ethiopia",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/cdb4e540223cc0612de6d46decea2c583c64baa66d8ab927f32c22c83483352d?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Gabon",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/be182cf3c2dcea0f28090a3c90586f7615ea1ed86caefbf58776696e2cb6b279?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Gambia",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/43136c9499f23c2c7f2c277fe207cf0eb51db5f34b00a6285d842bc5cf5bb4ab?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Ghana",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/bbb574448e980ce00576d6dc0329e95371360a114de0466bbf16ad5adbdf2a8b?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Guinea",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/7f76f5fa1c0ebf1e211507d04c06f50eb39365d0694586248d7fe3681faf74d4?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Guines Bissau",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/ca6a0af56183a9f6f37d4b1c674e3979cd9f0b68b0d2003de400cfc3697be3d3?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Cote d'ivoire",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/ca6ed522a546065abbdb47671e41fe7a6f37ee842b1d809c1f246747c7538361?apiKey=786e42c6526d4ad4bad3df542e370598&"
        },
        {
            name: "Kenya",
            flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/77576a704d14940fc5edfc015455b1508603baab5949484e12a07acad2961604?apiKey=786e42c6526d4ad4bad3df542e370598&"
        }
    ];
    return (
        <main className="flex md:ml-24 flex-col flex-wrap justify-center content-start items-center self-stretch px-7 mx-auto container mt-16 w-full max-md:px-5  h-max  overflow-scroll max-md:mt-10 max-md:max-w-full">
            {" "}
            <div className="flex gap-5 justify-between max-md:flex-wrap">
                {" "}
                {countries.slice(0, 6).map(country => (
                    <CountryCard key={country.name} country={country.name} flag={country.flag} />
                ))}{" "}
            </div>{" "}
            <div className="flex gap-5 justify-between mt-8 max-md:flex-wrap">
                {" "}
                {countries.slice(6, 11).map(country => (
                    <CountryCard key={country.name} country={country.name} flag={country.flag} />
                ))}{" "}
            </div>{" "}
            <div className="flex gap-5 justify-between mt-8 max-md:flex-wrap">
                {" "}
                {countries.slice(11, 17).map(country => (
                    <CountryCard key={country.name} country={country.name} flag={country.flag} />
                ))}{" "}
            </div>{" "}
            <div className="flex gap-5 justify-between self-stretch mt-8 max-md:flex-wrap">
                {" "}
                {countries.slice(17).map(country => (
                    <CountryCard key={country.name} country={country.name} flag={country.flag} />
                ))}{" "}
            </div>{" "}
        </main>
    );
}
function ContinentButton({ icon, name }) {
    return (
        <div className="flex gap-4 px-4 py-2 whitespace-nowrap border border-solid border-zinc-300 rounded-[88px]">
            <img loading="lazy" src={icon} alt="" className="shrink-0 my-auto w-6 aspect-square" />
            <div>{name}</div>
        </div>
    );
}

function MyComponent() {
    const continents = [
        {
            icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88bc5357181d0256e048f5b31a73af4c06a7a3b49842aa923f5c4714a6406815?apiKey=786e42c6526d4ad4bad3df542e370598&",
            name: "Europe"
        },
        {
            icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/654687698f40ad3171d403baa7c114ab679cabf0b1cf3573d8564fd253d9c9df?apiKey=786e42c6526d4ad4bad3df542e370598&",
            name: "North America"
        },
        {
            icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0c00f520df5e1b292e07f4b19e5557162605ccd2d5f14f01d67357c2aefd0944?apiKey=786e42c6526d4ad4bad3df542e370598&",
            name: "South America"
        },
        {
            icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e330b6a30fa02e4b260e92233bbc335356276507ddfe90935a28d3ca50c87b59?apiKey=786e42c6526d4ad4bad3df542e370598&",
            name: "Asia"
        },
        {
            icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6a29d82a6855d747de5263d98227a23c299d3f86212d09b0fd58f1d2bf9f68e0?apiKey=786e42c6526d4ad4bad3df542e370598&",
            name: "Oceania"
        }
    ];

    return (
        <section className="flex gap-5 justify-center px-5 mt-16 text-xl font-semibold leading-8 text-zinc-800 max-md:flex-wrap max-md:mt-10">
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4eb92aff72ff280cc60d26b13990ef3b1eda48c6625c9ffa2e2f418a4a5841d8?apiKey=786e42c6526d4ad4bad3df542e370598&"
                alt="Company Logo"
                className="shrink-0 max-w-full aspect-[2.63] w-[125px]"
            />
            {continents.map((continent, index) => (
                <ContinentButton key={index} icon={continent.icon} name={continent.name} />
            ))}
        </section>
    );
}
function SupportedCountries() {
    const [selectedContinent, setSelectedContinent] = useState("Africa");

    // Sample data for continents and their countries
    const continentData = {
        Africa: {
            countries: [
                { name: "Algeria", code: "DZA" },
                { name: "Angola", code: "AGO" },
                { name: "Benin", code: "BEN" },
                { name: "Botswana", code: "BWA" },
                { name: "Burkina Faso", code: "BFA" },
                { name: "Burundi", code: "BDI" },
                { name: "Cabo Verde", code: "CPV" },
                { name: "Cameroon", code: "CMR" },
                { name: "Central African Republic", code: "CAF" },
                { name: "Chad", code: "TCD" },
                { name: "Comoros", code: "COM" },
                { name: "Congo", code: "COG" },
                { name: "Democratic Republic of the Congo", code: "COD" },
                { name: "Djibouti", code: "DJI" },
                { name: "Egypt", code: "EGY" },
                { name: "Equatorial Guinea", code: "GNQ" },
                { name: "Eritrea", code: "ERI" },
                { name: "Eswatini", code: "SWZ" },
                { name: "Ethiopia", code: "ETH" },
                { name: "Gabon", code: "GAB" },
                { name: "Gambia", code: "GMB" },
                { name: "Ghana", code: "GHA" },
                { name: "Guinea", code: "GIN" },
                { name: "Guinea-Bissau", code: "GNB" },
                { name: "Ivory Coast", code: "CIV" },
                { name: "Kenya", code: "KEN" },
                { name: "Lesotho", code: "LSO" },
                { name: "Liberia", code: "LBR" },
                { name: "Libya", code: "LBY" },
                { name: "Madagascar", code: "MDG" },
                { name: "Malawi", code: "MWI" },
                { name: "Mali", code: "MLI" },
                { name: "Mauritania", code: "MRT" },
                { name: "Mauritius", code: "MUS" },
                { name: "Morocco", code: "MAR" },
                { name: "Mozambique", code: "MOZ" },
                { name: "Namibia", code: "NAM" },
                { name: "Niger", code: "NER" },
                { name: "Nigeria", code: "NGA" },
                { name: "Rwanda", code: "RWA" },
                { name: "Sao Tome and Principe", code: "STP" },
                { name: "Senegal", code: "SEN" },
                { name: "Seychelles", code: "SYC" },
                { name: "Sierra Leone", code: "SLE" },
                { name: "Somalia", code: "SOM" },
                { name: "South Africa", code: "ZAF" },
                { name: "South Sudan", code: "SSD" },
                { name: "Sudan", code: "SDN" },
                { name: "Tanzania", code: "TZA" },
                { name: "Togo", code: "TGO" },
                { name: "Tunisia", code: "TUN" },
                { name: "Uganda", code: "UGA" },
                { name: "Zambia", code: "ZMB" },
                { name: "Zimbabwe", code: "ZWE" }
            ],
            // Assuming you have continent images stored locally or hosted
            image: "/images/flags/afruca.png"
        },
        Europe: {
            countries: [
                { name: "Albania", code: "ALB" },
                { name: "Armenia", code: "ARM" },
                { name: "Austria", code: "AUT" },
                { name: "Belarus", code: "BLR" },
                { name: "Belgium", code: "BEL" },
                { name: "Bosnia & Herzegovina", code: "BIH" },
                { name: "Bulgaria", code: "BGR" },
                { name: "Croatia", code: "HRV" },
                { name: "Cyprus", code: "CYP" },
                { name: "Czech Republic", code: "CZE" },
                { name: "Denmark", code: "DNK" },
                { name: "Estonia", code: "EST" },
                { name: "Finland", code: "FIN" },
                { name: "France", code: "FRA" },
                { name: "Georgia", code: "GEO" },
                { name: "Germany", code: "DEU" },
                { name: "Gibraltar", code: "GIB" },
                { name: "Greece", code: "GRC" },
                { name: "Hungary", code: "HUN" },
                { name: "Iceland", code: "ISL" },
                { name: "Ireland", code: "IRL" },
                { name: "Italy", code: "ITA" },
                { name: "Kosovo", code: "XKX" },
                { name: "Latvia", code: "LVA" },
                { name: "Liechtenstein", code: "LIE" },
                { name: "Lithuania", code: "LTU" },
                { name: "Luxembourg", code: "LUX" },
                { name: "Malta", code: "MLT" },
                { name: "Moldova", code: "MDA" },
                { name: "Monaco", code: "MCO" },
                { name: "Montenegro", code: "MNE" },
                { name: "Netherlands", code: "NLD" },
                { name: "North Macedonia", code: "MKD" },
                { name: "Norway", code: "NOR" },
                { name: "Poland", code: "POL" },
                { name: "Portugal", code: "PRT" },
                { name: "Romania", code: "ROU" },
                { name: "Russia", code: "RUS" },
                { name: "San Marino", code: "SMR" },
                { name: "Serbia", code: "SRB" },
                { name: "Slovakia", code: "SVK" },
                { name: "Slovenia", code: "SVN" },
                { name: "Spain", code: "ESP" },
                { name: "Sweden", code: "SWE" },
                { name: "Switzerland", code: "CHE" },
                { name: "Turkey", code: "TUR" },
                { name: "Ukraine", code: "UKR" },
                { name: "United Kingdom", code: "GBR" }
                // Note: England is part of the United Kingdom and does not have a separate ISO code.
            ],
            image: "/images/flags/australia.png"
        },
        NorthAmerica: {
            countries: [
                { name: "USA", code: "USA" },
                { name: "Canada", code: "CAN" },
                { name: "Mexico", code: "MEX" }
            ],
            image: "/images/continents/northamerica.png"
        }
    };

    const handleContinentClick = continent => {
        setSelectedContinent(continent);
    };

    return (
        <div className="flex pb-10 relative flex-col bg-[#F7F6FD] sm:mb-80 overflow-scroll h-max lg:mb-24 w-full  ">
            {/* top */}
            <div className=" flex-col justify-center pt-10 font-normal space-y-5 items-center text-center w-full">
                <h1 className="text-4xl font-medium">Beyond Borders, Beyond Limits</h1>
                <p>Transfers made easy to over 150 countries.</p>
            </div>{" "}
            <MyComponent />
            {/* top center */}
            {/* <div className="flex flex-wrap mt-10 md:justify-center items-center  space-x-2 w-full h-full">
                {Object.keys(continentData).map(continent => (
                    <>
                        {" "}
                        <div
                            className="flex gap-4 px-4 py-2 whitespace-nowrap border border-solid border-zinc-300 rounded-[88px]"
                            onClick={() => handleContinentClick(continent)}
                        >
                            <img
                                loading="lazy"
                                src={continentData[continent].image}
                                alt={continent}
                                className="shrink-0 my-auto w-6 aspect-square"
                            />
                            <h4>{continent}</h4>
                        </div>{" "}
                    </>
                ))}
            </div> */}
            {/* bottom */}
            <CountriesCom />
            <img
                src="/images/ellipse.png"
                alt=""
                className="absolute bottom-0 hidden md:flex top-72 h-96 w-96 left-[484px] mb-96  "
            />
        </div>
    );
}

export default SupportedCountries;
