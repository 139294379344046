import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useCreateRecipientMutation } from "../../store/recipient";
import { useDispatch } from "react-redux";
import { setRecipientData } from "../../store/Slices/transferSlice";
import Alert from "../controllers/Alert";
import { CountryDropdown, PhoneInput } from "../controllers/Countries";

function InputField({ label, name, placeholder, type = "text", value, handleChange }) {
    return (
        <>
            <div className="mt-4 text-sm text-left leading-6 text-neutral-500 text-[#8d8b8b] max-md:max-w-full">
                {label}
            </div>
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                className="w-full px-4 py-2 mt-1 text-sm leading-6 text-gray-700 bg-white rounded-md border border-secondary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent max-md:max-w-full"
            />
        </>
    );
}

function Button({ children, variant = "primary", onClick }) {
    const baseClasses =
        "flex-1 justify-center px-6 py-2 text-sm font-medium leading-6 rounded-md max-md:px-5";
    const variantClasses = {
        primary:
            "text-secondary bg-[#230082] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
        secondary:
            "bg-white border border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
    };

    return (
        <button className={`${baseClasses} ${variantClasses[variant]}`} onClick={onClick}>
            {children}
        </button>
    );
}

function AddRecipientModal({ isOpen, onClose, closeModal, refetch }) {
    const countries = [
        {
            name: "United States",
            code: "+1",
            flag: "https://hatscripts.github.io/circle-flags/flags/us.svg"
        },
        {
            name: "Canada",
            code: "+1",
            flag: "https://hatscripts.github.io/circle-flags/flags/ca.svg"
        },
        {
            name: "United Kingdom",
            code: "+44",
            flag: "https://hatscripts.github.io/circle-flags/flags/gb.svg"
        }
    ];

    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        selectedCountry: countries[0],
        phone: "",
        bankAccounts: [] // Initialize bankAccounts array
    });

    const [newAccount, setNewAccount] = useState({
        accountType: "",
        IBAN: ""
    });

    const dispatch = useDispatch();
    const [recipient, { isLoading }] = useCreateRecipientMutation();

    const handleAddRecipient = async () => {
        try {
            const data = {
                firstName: formData.firstName || "",
                lastName: formData.lastName || "",
                Email: formData.email || "",
                selectedCountry: formData.selectedCountry?.name || "",
                phoneNumber: formData.phone || "",
                Reason: "",
                bankAccounts: formData.bankAccounts || [] // Ensure bankAccounts is not undefined
            };

            const response = await recipient(data).unwrap();

            dispatch(setRecipientData(response?.firstName));
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                selectedCountry: countries[0],
                phone: "",
                bankAccounts: [] // Reset bankAccounts after submission
            });
            closeModal();
            refetch();
        } catch (error) {
            console.error("Error adding recipient:", error);
            if (error.data && error.data.errors) {
                const { firstName, city, phone, selectedCountry } = error.data.errors;
                setError(
                    firstName ||
                        city ||
                        phone ||
                        selectedCountry ||
                        "An error occurred. Please try again later."
                );
            } else {
                setError("An error occurred. Please try again later.");
            }
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleAccountChange = e => {
        const { name, value } = e.target;
        setNewAccount(prevAccount => ({ ...prevAccount, [name]: value }));
    };

    const addBankAccount = () => {
        if (newAccount.accountType && newAccount.IBAN) {
            setFormData(prevData => ({
                ...prevData,
                bankAccounts: [...prevData.bankAccounts, newAccount]
            }));
            setNewAccount({ accountType: "", IBAN: "" }); // Reset new account fields
        }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.2 }}
                    className="fixed inset-0 z-50 flex items-center justify-center"
                >
                    <div className="absolute inset-0 bg-black opacity-50" onClick={onClose} />
                    <div className="relative z-10 flex flex-col p-8 font-semibold bg-white rounded-xl border border-gray-100 border-solid max-w-lg w-full max-md:px-5">
                        <h2 className="mt-10 text-2xl leading-8 text-slate-900 max-md:max-w-full">
                            Add Recipient
                        </h2>
                        <p className="mt-2 text-sm mb-10 leading-6 text-zinc-500 max-md:max-w-full">
                            Add a recipient with a bank account
                        </p>
                        <div className="h-[300px] overflow-y-auto">
                            {error && <Alert type="error" message={error} />}
                            <InputField
                                label="First Name"
                                name="firstName"
                                placeholder="Enter first name"
                                value={formData.firstName}
                                handleChange={handleChange}
                            />
                            <InputField
                                label="Last Name"
                                name="lastName"
                                placeholder="Enter last name"
                                value={formData.lastName}
                                handleChange={handleChange}
                            />
                            <InputField
                                label="Email"
                                name="email"
                                placeholder="Enter email"
                                type="email"
                                value={formData.email}
                                handleChange={handleChange}
                            />
                            <div className="mt-2 space-y-3">
                                <CountryDropdown
                                    selectedCountry={formData.selectedCountry}
                                    setSelectedCountry={country =>
                                        setFormData(prevData => ({
                                            ...prevData,
                                            selectedCountry: country
                                        }))
                                    }
                                    countries={countries}
                                />
                                <PhoneInput
                                    selectedCountry={formData.selectedCountry}
                                    value={formData.phone}
                                    handleChange={handleChange}
                                />
                            </div>

                            <div className="mt-4">
                                <h3 className="text-lg font-semibold">Add Bank Account</h3>
                                <InputField
                                    label="Account Type"
                                    name="accountType"
                                    placeholder="Enter account type"
                                    value={newAccount.accountType}
                                    handleChange={handleAccountChange}
                                />
                                <InputField
                                    label="IBAN"
                                    name="IBAN"
                                    placeholder="Enter IBAN"
                                    value={newAccount.IBAN}
                                    handleChange={handleAccountChange}
                                />
                                <div className="mt-10">
                                    <Button variant="primary" onClick={addBankAccount}>
                                        Add Bank Account
                                    </Button>
                                </div>
                            </div>

                            <div className="mt-4">
                                <h3 className="text-lg font-semibold">Bank Accounts</h3>
                                <ul>
                                    {formData.bankAccounts.length > 0 ? (
                                        formData.bankAccounts.map((account, index) => (
                                            <li key={index} className="border p-2 mb-2 rounded-md">
                                                <p>
                                                    <strong>Account Type:</strong>{" "}
                                                    {account.accountType}
                                                </p>
                                                <p>
                                                    <strong>IBAN:</strong> {account.IBAN}
                                                </p>
                                            </li>
                                        ))
                                    ) : (
                                        <p>No bank accounts added.</p>
                                    )}
                                </ul>
                            </div>
                        </div>

                        <div className="flex gap-4 justify-end mt-6 max-md:flex-wrap max-md:max-w-full">
                            <Button variant="secondary" onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button onClick={handleAddRecipient} disabled={isLoading}>
                                {isLoading ? "Adding..." : "Add and Continue"}
                            </Button>
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default AddRecipientModal;
