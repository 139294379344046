import * as React from "react";
import Logo from "../Logo/Logo";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Navigation2OffIcon } from "lucide-react";
import { useVeriffCheckStatusQuery } from "../../store/Veriff";
import { useGetTransactionsQuery } from "../../store/Transactions";

const sidebarItems = [
    {
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8c9b6cacc0c3b3072b1f0041a8a8606d46f31a0470c77b78d77c4baea359f813?apiKey=786e42c6526d4ad4bad3df542e370598&",
        label: "Home"
    },
    {
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/474b0f44f776ed9ecbc8d0e63018933cb2d0f6098f3fbfba1e54159435e93ea2?apiKey=786e42c6526d4ad4bad3df542e370598&",
        label: "Recipients"
    },
    {
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e71fe302d3338e6a4b18dd9aeb89c2ed1562c7f126f2f3c5d4569a68fc4695d8?apiKey=786e42c6526d4ad4bad3df542e370598&",
        label: "Transfers",
        active: true
    },
    {
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f05038faaff40d42f44fa367a7d60c072579d8644f75b557431d374f65988d71?apiKey=786e42c6526d4ad4bad3df542e370598&",
        label: "Payment"
    },
    {
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3390b650724ee9f23cc5239d9e92a02761268d613dff93935803a464a2a15fab?apiKey=786e42c6526d4ad4bad3df542e370598&",
        label: "Settings"
    }
];
const transferStatuses = [
    { label: "All", count: 12, active: true },
    { label: "Successful", count: 10 },
    { label: "Pending", count: 1 },
    { label: "Failed", count: 1 }
];
// const transfers = [
//     {
//         id: "TR- 12342343",
//         recipient: "Khvicha Kvaratskhelia Srna",
//         amountSent: {
//             value: 120,
//             currency: "GBP",
//             icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/44eedc148ea8b30d4fa1a0928ca3a1c5513dcb4816f6531fc721122a70867fe2?apiKey=786e42c6526d4ad4bad3df542e370598&"
//         },
//         amountConverted: {
//             value: 1000000,
//             currency: "NGN",
//             icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/65c52019997234540cc97cf7dbcd468a35708cee8d5c377ba457b1c8fdcaa851?apiKey=786e42c6526d4ad4bad3df542e370598&"
//         },
//         timeSent: "16:30",
//         dateSent: "01.06.2023",
//         status: "Pending",
//         statusColor: "gray"
//     },
//     {
//         id: "TR- 12342343",
//         recipient: "Akwa Abasiekeme",
//         amountSent: {
//             value: 120,
//             currency: "USD",
//             icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ffdbf2a03ba2e75176bf63c6561d4c302270a17053ac97fcea2655fd25735dab?apiKey=786e42c6526d4ad4bad3df542e370598&"
//         },
//         amountConverted: {
//             value: 160.52,
//             currency: "CAD",
//             icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/565b8cf51d1a0ca7b3dc685fe05617e3a67a4532bd8ec17eb0574d700748d678?apiKey=786e42c6526d4ad4bad3df542e370598&"
//         },
//         timeSent: "16:30",
//         dateSent: "01.06.2023",
//         status: "Success",
//         statusColor: "green"
//     },
//     {
//         id: "TR- 12342343",
//         recipient: "Tom Aspinall Rosenthal",
//         amountSent: {
//             value: 120,
//             currency: "EUR",
//             icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/732900339bf9b620b527ee35eddd50bb06ecae8f263e1b52b8bf939ba96a00fc?apiKey=786e42c6526d4ad4bad3df542e370598&"
//         },
//         amountConverted: {
//             value: 1000000,
//             currency: "NGN",
//             icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/050de7188df363438cb0bb97af19820ee4bef95cf5a4828afbe0a72b89d07487?apiKey=786e42c6526d4ad4bad3df542e370598&"
//         },
//         timeSent: "16:30",
//         dateSent: "01.06.2023",
//         status: "Failed",
//         statusColor: "red"
//     }
// ];
function SidebarItem({ icon, label, active }) {
    return (
        <div className={`flex gap-2 mt-8 ${active ? "font-bold text-orange-600" : ""}`}>
            {" "}
            <img loading="lazy" src={icon} alt="" className="shrink-0 w-6 aspect-square" />{" "}
            <div className="flex-1 cursor-pointer">{label}</div>{" "}
        </div>
    );
}
function Sidebar() {
    return (
        <aside className="hidden md:flex flex-col w-[16%] max-md:ml-0 max-md:w-full">
            {" "}
            <div className="flex flex-col   justify-center px-4 py-20 w-full text-sm leading-6 whitespace-nowrap bg-white border-r  border-secondary border-solid text-slate-700 max-md:mt-4">
                <div className="-mt-14">
                    <Logo />
                </div>
                <div className="flex flex-col px-4 py-2 mt-9">
                    {" "}
                    {sidebarItems.map(item => (
                        <SidebarItem
                            key={item.label}
                            icon={item.icon}
                            label={item.label}
                            active={item.active}
                        />
                    ))}{" "}
                </div>{" "}
            </div>{" "}
        </aside>
    );
}
function UserAvatar() {
    return (
        <div className="flex justify-center items-center">
            {" "}
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d1243a0adf2e4e4e6700e842606993dbec92fbd0bd47ef76ab0e74661b8cac9?apiKey=786e42c6526d4ad4bad3df542e370598&"
                alt="User avatar"
                className="w-10 h-10 bg-violet-100 rounded-full aspect-square"
            />{" "}
        </div>
    );
}
function UserInitials() {
    return (
        <div className="flex gap-0.5 px-px text-base font-semibold leading-6 text-center whitespace-nowrap text-slate-900">
            {" "}
            <div className="flex flex-col justify-center">
                {" "}
                <div className="justify-center flex items-center px-1 w-14 h-14 bg-[#ee82ee] rounded-full">
                    AB{" "}
                </div>{" "}
            </div>{" "}
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/930dd613f377524754a7c3b42b1222e3347c9e05539b4660e586fbc48e6f75ee?apiKey=786e42c6526d4ad4bad3df542e370598&"
                alt=""
                className="shrink-0 my-auto w-[34px] aspect-square"
            />{" "}
        </div>
    );
}
function Header() {
    return (
        <header className="flex flex-col justify-center items-end px-16 py-3 bg-white border-b border-secondary border-solid max-md:px-5 max-md:max-w-full">
            {" "}
            <div className="flex gap-5  ">
                {" "}
                <>
                    <Menu>
                        <div className=" ">
                            <div className="flex items-center ">
                                <Navigation2OffIcon />
                                <Menu.Button className="ml-8 focus:outline-none">
                                    <img
                                        className="w-9 h-9 rounded-md"
                                        src="/images/veriffied.png"
                                        alt=""
                                    />
                                </Menu.Button>
                                {/* <div className="m-2">
                                            <Language />{" "}
                                        </div> */}
                            </div>

                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <Menu.Items>
                                    <div className="absolute  right-0 z-10 w-48 px-2 py-1 mt-1 text-gray-600 bg-white border border-secondary rounded-md shadow">
                                        <Menu.Item>
                                            <Link
                                                className="flex items-center cursor-pointer space-x-3 px-3 py-2.5 text-sm hover:text-purple-500"
                                                to="/" // Add onClick handler to toggle visibility
                                            >
                                                <svg
                                                    className="w-5 h-5"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                </svg>
                                                <span>Profile</span>
                                            </Link>
                                        </Menu.Item>
                                        <hr className="border-secondary" />
                                        <Menu.Item>
                                            <button className="flex items-center space-x-3 px-3 py-2.5 text-sm hover:text-purple-500">
                                                <svg
                                                    className="w-5 h-5"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                                    />
                                                </svg>
                                                <span>Logout</span>
                                            </button>
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </div>
                    </Menu>
                </>
            </div>{" "}
        </header>
    );
}
function SearchInput() {
    return (
        <div className="flex flex-1 gap-2 self-start p-0.5 mt-5 text-sm leading-6 bg-white rounded-md border border-secondary border-zinc-300 text-zinc-400 max-md:flex-wrap">
            {" "}
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/1373cca4cc70425e8c253ab26230d221c1dcc02a2e55d68a5e20cd608992e5ac?apiKey=786e42c6526d4ad4bad3df542e370598&"
                alt=""
                className="shrink-0 my-auto w-5 aspect-square"
            />{" "}
            <input
                placeholder="Search name, currency "
                className="right-0 border-none focus:ring-0"
            />{" "}
        </div>
    );
}
function CurrencyFilter() {
    return (
        <div className="flex flex-col whitespace-nowrap">
            {" "}
            <label htmlFor="currencyFilter" className="leading-[133%] text-slate-900">
                {" "}
                Currency{" "}
            </label>{" "}
            <div className="flex flex-col justify-center px-3 py-1.5 mt-1 w-full bg-white rounded-md border border-secondary border-solid shadow-sm leading-[160%] text-slate-500">
                {" "}
                <div className="flex gap-1 justify-between">
                    {" "}
                    <div>All</div>{" "}
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f47ef76f0c2071fdbddbdff6daab44f7f3bd7bd4a00c9170ed152ba1199cf852?apiKey=786e42c6526d4ad4bad3df542e370598&"
                        alt=""
                        className="shrink-0 self-start w-4 aspect-square"
                    />{" "}
                </div>{" "}
            </div>{" "}
        </div>
    );
}
function TransferDateFilter() {
    return (
        <div className="flex flex-col">
            {" "}
            <label htmlFor="transferDateFilter" className="leading-[133%] text-slate-900">
                {" "}
                Transfer Date{" "}
            </label>{" "}
            <div className="flex flex-col justify-center px-3 py-1.5 mt-1 w-full bg-white rounded-md border border-secondary border-solid shadow-sm leading-[160%] text-slate-500">
                {" "}
                <div className="flex gap-1">
                    {" "}
                    <div>21 - 1 -2023</div> <div>to</div> <div>21 - 1 -2023</div>{" "}
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc5e3c728d4689029ae57814f3bffaaa41afc06e7bbdfbde21b7273f0d86c4f6?apiKey=786e42c6526d4ad4bad3df542e370598&"
                        alt=""
                        className="shrink-0 self-start w-4 aspect-square"
                    />{" "}
                </div>{" "}
            </div>{" "}
        </div>
    );
}
function TransferStatusFilter({ label, count, active }) {
    return (
        <div className="flex flex-col">
            {" "}
            <div className="flex cursor-pointer hover:text-primary gap-1.5">
                {" "}
                <div
                    className={`text-sm font-semibold leading-6
                ${label === "pending" ? "text-primary" : "text-neutral-500"}
                `}
                >
                    {}
                    {label}
                </div>{" "}
                <div className="justify-center px-2 py-0.5 my-auto text-xs leading-4 rounded-3xl border border-secondary border-zinc-300">
                    {" "}
                    {count}{" "}
                </div>{" "}
            </div>{" "}
            <div
                className={`shrink-0 h-0.5 rounded-sm ${
                    active ? "bg-violet-950" : "bg-neutral-100"
                }`}
            />{" "}
        </div>
    );
}
function TransferStatusFilters() {
    return (
        <div className="flex gap-4 self-start pt-2 pr-16 ml-3 whitespace-nowrap border-b border-secondary border-neutral-100 text-zinc-500 max-md:flex-wrap max-md:pr-5">
            {" "}
            {transferStatuses.map(status => (
                <TransferStatusFilter
                    key={status.label}
                    label={status.label}
                    count={status.count}
                    active={status.active}
                />
            ))}{" "}
        </div>
    );
}
function TransferTableHeader() {
    return (
        <div className="flex flex-col justify-center mt-4 text-sm font-semibold tracking-wide leading-6 bg-violet-100 rounded text-zinc-500 max-md:max-w-full">
            <div className="flex gap-2 justify-between px-3 py-2 max-md:flex-wrap">
                <div className="text-ellipsis">Transfer ID</div> <div>Recipient</div>{" "}
                <div>Amount Sent</div> <div>Amount Converted</div> <div>Time sent</div>{" "}
                <div>Status</div>{" "}
            </div>{" "}
        </div>
    );
}
function TransferTableRow({ list }) {
    return (
        <div className="flex flex-col justify-center mt-3 text-sm leading-6 border-b border-secondary border-solid max-md:max-w-full">
            <div className="flex gap-10 justify-between items-end p-3 w-full max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
                <div className="self-stretch my-auto w-[80px] font-medium tracking-wide text-indigo-600 underline text-ellipsis">
                    {list?.data?.id}
                </div>
                <div className="self-stretch my-auto font-semibold text-neutral-600">
                    {list?.data?.payeeDetails?.name}
                </div>
                <div className="flex gap-1 self-stretch my-auto text-neutral-500">
                    <div className="text-ellipsis">
                        <span className="font-semibold text-slate-900">
                            {list?.data?.amountDetails?.amount}
                        </span>
                        <span className="font-semibold text-neutral-500">
                            {list?.data?.amountDetails?.currency}
                        </span>
                    </div>
                </div>
                <div className="flex gap-1 self-stretch my-auto text-neutral-500">
                    <div className="text-ellipsis">
                        <span className="font-semibold text-slate-900">
                            {list?.data?.amountDetails?.amount}
                        </span>
                        <span className="font-semibold text-neutral-500">
                            {list?.data?.amountDetails?.currency}
                        </span>
                    </div>
                </div>
                <div className="flex gap-1 justify-between self-stretch my-auto whitespace-nowrap text-neutral-600">
                    <div>{list?.createdAt}</div>
                </div>
                <div
                    className={`justify-center items-start self-stretch px-5 py-1.5 text-xs font-semibold leading-4 text-[#000000] whitespace-nowrap bg-[#00000] text-secondary rounded-md max-md:pl-5`}
                >
                    <p className="text-[#000]"> {list?.data?.status}</p>
                </div>
            </div>
        </div>
    );
}
function TransferTable({ transactions }) {
    return (
        <div className="flex flex-col mb-10 justify-center px-3 pt-3 mt-6 rounded-xl border border-secondary border-solid max-md:max-w-full">
            <TransferStatusFilters /> <TransferTableHeader />{" "}
            {transactions?.map(transfer => (
                <TransferTableRow key={transfer?.data?.id} list={transfer} />
            ))}{" "}
        </div>
    );
}
function TransfersPage({ transactions }) {
    return (
        <main className="flex flex-col ml-5 w-[80%] max-md:ml-0 max-md:w-full">
            {" "}
            <div className="flex flex-col max-md:mt-4 max-md:max-w-full">
                {" "}
                <Header />{" "}
                <section className="flex flex-col self-center px-6 pt-6 mt-16 w-full bg-white rounded-xl border border-solid border-secondary max-w-[1108px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    {" "}
                    <h1 className="text-xl font-semibold tracking-wide text-slate-900 max-md:max-w-full">
                        {" "}
                        Transfers{" "}
                    </h1>{" "}
                    <div className="flex gap-4 pr-16 mt-2 text-xs font-semibold max-md:flex-wrap max-md:pr-5">
                        {" "}
                        <SearchInput />
                        <CurrencyFilter />
                        <TransferDateFilter />{" "}
                    </div>{" "}
                    <TransferTable transactions={transactions} />{" "}
                </section>{" "}
            </div>{" "}
        </main>
    );
}
const TemplateReact = () => {
    const {
        data: transactions,
        isLoading: transLoading,
        Error: transError,
        refetch
    } = useGetTransactionsQuery();

    console.log("Transactions : ");

    return (
        <div className="pb-2 bg-gray-50">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <Sidebar />
                <TransfersPage transactions={transactions} />
            </div>
        </div>
    );
};

export default TemplateReact;
