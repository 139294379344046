import * as React from "react";
import Nav from "../layouts/Nav";
import Footer from "./Footer";

function ContactForm() {
    return (
        <form className="flex md:ml-24 text-start flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col flex-wrap content-start max-md:max-w-full">
                <div className="flex gap-5 justify-between text-sm leading-6 max-md:flex-wrap">
                    <div className="flex flex-col">
                        <label
                            htmlFor="firstName"
                            className="justify-center font-semibold text-[#64748b]"
                        >
                            First name
                        </label>
                        <input
                            type="text"
                            id="firstName"
                            placeholder="Enter your first name"
                            className="flex flex-col  justify-start p-2.5 px-16   mt-1 text-[#9ca3af] bg-white rounded-md border border-[#d1d5db] border-solid"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label
                            htmlFor="lastName"
                            className="justify-center font-semibold text-[#64748b]"
                        >
                            Last name
                        </label>
                        <input
                            type="text"
                            id="lastName"
                            placeholder="Enter your last name"
                            className="flex flex-col justify-center p-3 px-16 text-starts mt-1 text-[#9ca3af] bg-white rounded-md border border-[#d1d5db] border-solid"
                        />
                    </div>
                </div>
                <div className="flex flex-col mt-6 text-sm leading-6 max-md:max-w-full">
                    <label
                        htmlFor="email"
                        className="justify-center font-semibold text-[#64748b] max-md:max-w-full"
                    >
                        Email Address
                    </label>
                    <input
                        type="email"
                        id="email"
                        placeholder="Enter your email address"
                        className="flex flex-col justify-center p-3 mt-1 text-[#9ca3af] bg-white rounded-md border border-[#d1d5db] border-solid max-md:max-w-full"
                    />
                </div>
                <fieldset className="flex flex-col mt-6 text-sm text-[#0f172a] max-md:max-w-full">
                    <legend className="font-semibold leading-[143%] max-md:max-w-full">
                        Select Subject?
                    </legend>
                    <div className="flex gap-4 pr-20 mt-4 leading-6 max-md:flex-wrap max-md:pr-5">
                        <div className="flex gap-2">
                            <input
                                type="radio"
                                name="subject"
                                id="general"
                                className="shrink-0 my-auto bg-white rounded-xl border border-solid border-[#e4e4e7] h-[18px] w-[18px]"
                            />
                            <label htmlFor="general">General Inquiry</label>
                        </div>
                        <div className="flex gap-2">
                            <input
                                type="radio"
                                name="subject"
                                id="feedback"
                                className="shrink-0 my-auto border border-[#4f46e5] border-solid aspect-square w-[18px]"
                                defaultChecked
                            />
                            <label htmlFor="feedback">Feedback</label>
                        </div>
                        <div className="flex gap-2">
                            <input
                                type="radio"
                                name="subject"
                                id="support"
                                className="shrink-0 my-auto bg-white rounded-xl border border-solid border-[#e4e4e7] h-[18px] w-[18px]"
                            />
                            <label htmlFor="support">Support</label>
                        </div>
                        <div className="flex gap-2">
                            <input
                                type="radio"
                                name="subject"
                                id="other"
                                className="shrink-0 my-auto bg-white rounded-xl border border-solid border-[#e4e4e7] h-[18px] w-[18px]"
                            />
                            <label htmlFor="other">Other</label>
                        </div>
                    </div>
                </fieldset>
                <div className="flex flex-col justify-center mt-6 max-md:max-w-full">
                    <label
                        htmlFor="message"
                        className="justify-center text-sm font-semibold leading-6 whitespace-nowrap text-[#64748b] max-md:max-w-full"
                    >
                        Message
                    </label>
                    <div className="flex flex-col justify-center mt-1 max-md:max-w-full">
                        <textarea
                            id="message"
                            placeholder="Leave a message"
                            className="flex flex-col justify-center p-3 bg-white rounded-md border border-[#d1d5db] border-solid max-md:max-w-full text-sm leading-6 text-[#475569]"
                        ></textarea>
                    </div>
                </div>
            </div>
            <button
                type="submit"
                className="flex flex-col justify-center self-end px-10 py-2.5 mt-10 w-52 max-w-full text-sm font-bold leading-7 text-secondary bg-[#4f46e5] rounded-md shadow-sm max-md:px-5"
            >
                Send Message
            </button>
        </form>
    );
}

function ContactInfo() {
    return (
        <div className="flex flex-col w-[38%] max-md:ml-0 max-md:w-full">
            <section className="flex flex-col  px-6 pt-10 pb-20 w-full bg-[#ede9fe] rounded-xl text-[#0f172a] max-md:px-5 max-md:mt-10">
                <h2 className="text-3xl font-medium">Contact Us</h2>
                <div className="flex flex-col items-start mt-14 text-base leading-6 max-md:mt-10">
                    <p className="pb-20">
                        We value your inquiries, feedback, and concerns. Reach out to us via the
                        contact form, and one of our knowledgeable representatives will respond to
                        you via email as quickly as possible.{" "}
                    </p>
                    <p className="mt-6 mb-9">
                        You can also reach out to us via the following mediums and on social media.
                    </p>
                </div>
                <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/1353d101de1ff1fe83808a1df1aec90128be7650d8f14c97fb9827cb14238e68?apiKey=17a805635469445292633231424b6dde&"
                    alt=""
                    className="z-10 mb-0 w-full rounded-md aspect-[1.33] max-md:mb-2.5"
                />
            </section>
        </div>
    );
}

export default function ContactUs() {
    return (
        <>
            <Nav />
            <div className="flex pt-32 px-10 mb-24 flex-col justify-center p-7 bg-[#ffffff] rounded-xl shadow-2xl max-md:px-5">
                <div className="max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <ContactInfo />
                        <div className="flex flex-col md:ml-5   max-md:ml-0 max-md:w-full">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
