import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../helpers";
import { useGetRecipientsQuery } from "../../store/recipient";
import { setRecipientData } from "../../store/Slices/transferSlice";

const List = ({ onButtonClick, data, isLoading, error }) => {
    const dispatch = useDispatch();
    const selectRecipt = useSelector(state => state.transfer.recipient);
    // const { data, error, isLoading } = useGetRecipientsQuery();
    const recipients = data; // Access recipients property safely using optional chaining

    console.log("Data = ", data);

    const handleRecipientClick = recipient => {
        dispatch(setRecipientData(recipient));
    };

    console.log(data);
    if (error?.data?.message) {
        logout();
    }
    return (
        <div className="h-[265px] px-4 overflow-y-auto">
            {isLoading ? (
                <div className="grid grid-cols-1 gap-6 mt-10 w-full">
                    <div className="card">
                        <div className="flex space-x-4">
                            <div className="h-12 w-12 rounded-full loading"></div>
                            <div className="flex-1">
                                <div className="h-6 w-36 loading mb-1"></div>
                                <div className="h-4 mb-1 loading"></div>
                                <div className="h-4 mb-1 loading"></div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {recipients && recipients.length > 0 ? (
                        recipients.map(recipient => (
                            <div
                                onClick={() => handleRecipientClick(recipient)}
                                className="flex flex-col justify-center -ml-4 pl-0 mr-1 mx-auto px-6 py-4 mt-1 bg-white rounded-xl border border-solid border-[#d4d4d8]"
                            >
                                <div className="flex gap-4 px-px">
                                    <div className="flex flex-1 gap-4">
                                        <div className="flex flex-col justify-center px-0.5 my-auto text-sm font-medium whitespace-nowrap text-[#0f172a]">
                                            <div className="justify-center items-center px-3 w-11 h-11 bg-[#ddd6fe] flex rounded-full">
                                                PG
                                            </div>
                                        </div>
                                        <div className="flex flex-col flex-1 py-1">
                                            <div className="text-base  text-left font-medium tracking-wider leading-6 text-[#0f172a]">
                                                {recipient?.firstName} {recipient.lastName}{" "}
                                            </div>
                                            <div className="flex gap-2 mt-2 text-sm text-[#525252]">
                                                <div className="flex gap-1">
                                                    <div>{recipient.selectedCountry} </div>
                                                </div>
                                                <div>{recipient.phoneNumber}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        checked={
                                            recipient?._id === selectRecipt?._id ? true : false
                                        }
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-blue-600 shrink-0 my-auto w-6 h-6 border border-solid border-[#d4d4d4] fill-white stroke-[1px] stroke-[#d4d4d4]"
                                    />
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="mt-10 ">No recipients found please add New one !!!.</p>
                    )}
                </>
            )}
        </div>
    );
};

export default List;
