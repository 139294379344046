import AnimatedSVG from "../../helpers/Animat";
import PaymentCard from "../controllers/PaymentCard";

const QRPage = ({ data, onButtonClick, consentData }) => {
    console.log("url : ", data?.data);

    console.log("====================================");
    console.log("ConsentDAta : ", consentData);
    console.log("====================================");
    return (
        <PaymentCard>
            <div className="bg-white p-6 rounded-lg shadow-md w-[400px]">
                {!consentData ? (
                    <h2 className="text-lg font-semibold mb-4">
                        Waiting for your bank to approve payment
                    </h2>
                ) : (
                    <h2 className="text-lg font-semibold mb-4">Consent Approved by Bank</h2>
                )}

                {!consentData ? (
                    <>
                        <div className="mb-8">
                            {/* QR Code Placeholder */}
                            <img
                                src={`${data?.data?.data?.qrCodeUrl}`} // Replace with the actual path to your QR code image
                                alt="QR Code"
                                className="mx-auto w-40"
                            />
                        </div>
                        <p className="text-gray-700 text-sm mb-4">
                            Scan the QR code to launch your mobile app
                        </p>
                        <p className="text-gray-700 text-sm mb-2">
                            Or click the link below to securely connect via
                        </p>
                        <a
                            href={data?.data?.data?.authorisationUrl}
                            target="_blank"
                            className="text-blue-600 font-semibold hover:underline"
                        >
                            Online Banking
                        </a>
                    </>
                ) : (
                    <div className="flex justify-center mb-4">
                        {/* Logo Placeholder */}
                        <img
                            src="/images/success.png" // Replace with the actual path to your logo
                            alt="Logo"
                            className="h-36"
                        />
                    </div>
                )}

                {!consentData ? (
                    <AnimatedSVG className="w-full h-16 text-white" />
                ) : (
                    <button
                        onClick={onButtonClick}
                        className="w-full bg-[#4f46e5] mt-10 text-[#ffffff] py-3 rounded mb-3 hover:bg-purple-700 hover:text-[#ffffff]"
                    >
                        Continue
                    </button>
                )}
            </div>
        </PaymentCard>
    );
};

export default QRPage;
