/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Flags } from "../../helpers/flags";
import { RecievingCountries, SendingCountries } from "../../helpers/staticObjects";
import { setTransactionData } from "../../store/Slices/transferSlice";
import add from "./add.png";
import equal from "./equal.png";
import { Selection } from "./field";
import rate from "./rate.png";

const TransferForm = ({ button }) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedOption, setSelected] = useState({
        currency: "GBP",
        image: Flags.england,
        country: "United Kingdom"
    });
    const [enteredValue, setEntered] = useState(1);
    const [selectedCurrent, setCurrency] = useState();
    const [oppositeOption, setOption] = useState({
        currency: "KES",
        image: Flags.kenya,
        country: "Kenya"
    });
    const [findedValue, setFinded] = useState(0);
    const [charge, setCharge] = useState(0);
    const [conversion, setConversion] = useState();
    const navigate = useNavigate();

    console.log("Entered Value : ", enteredValue);

    const handleCalculate = async () => {
        try {
            const response = await axios.get(
                `https://api.faremit.com/api/paircode/${selectedOption?.currency}/${oppositeOption?.currency}/1`
            );
            setConversion(response?.data);
        } catch (error) {
            console.log("Error api: ", error?.message);
        }
    };

    useEffect(() => {
        handleCalculate();
    }, [selectedOption, oppositeOption]);

    const handleChange = newOption => {
        setSelected(newOption);
    };

    const handleInput = e => {
        setEntered(e.target.value);
        dispatch(setTransactionData(e.target.value));
    };

    const handleOppositeChange = newOption => {
        setOption(newOption);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const token = localStorage.getItem("token");

        if (token) {
            setTimeout(() => {
                navigate("/send");
            }, 100);
        } else {
            navigate("/login");
        }
    };

    return (
        <div
            className={`flex justify-center items-start w-full md:w-[550px] bg-green-500 gap-2 rounded-xl`}
        >
            <div className="flex p-[5px] z-50 justify-center items-start w-full h-full relative gap-2 rounded-xl bg-white shadow-md">
                <div className="flex flex-col p-[20px] w-full justify-start items-start flex-grow-0 flex-shrink-0 S md:left-4 gap-3">
                    <Selection
                        currencyArr={SendingCountries}
                        readOnly={false}
                        handleChange={handleChange}
                        selectedOption={selectedOption}
                        enteredValue={enteredValue}
                        handleInput={handleInput}
                        title="You send"
                        istwo={true}
                    />
                    <div className="flex justify-between cursor-not-allowed items-center flex-grow-0 flex-shrink-0 w-full  px-5 py-3 rounded-xl  border border-secondary">
                        <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-1">
                            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#5c5676]">
                                Payment Method
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left text-[#001140]">
                                Bank Transfer
                            </p>
                        </div>
                        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-3">
                            <svg
                                width={16}
                                height={17}
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M2.66666 6.5L7.99999 11.8333L13.3333 6.5"
                                    stroke="#001140"
                                    stroke-width="1.66667"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="flex flex-col bg-secondary justify-start items-start flex-grow-0 flex-shrink-0 w-full n gap-5 px-5 py-3 rounded-xl">
                        <div className="flex flex-col  justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4 pl-4 border-t-0 border-r-0 border-b-0 border-l border-secondary">
                            <div className="flex justify-between items-start self-stretch flex-grow-0 flex-shrink-0 relative">
                                <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#4e4c54]">
                                    Fee
                                </p>
                                <p className="flex-grow-0 flex-shrink-0 text-lg font-semibold text-left text-[#2e2b3b]">
                                    <span>{charge}%</span>{" "}
                                </p>
                                <img
                                    src={add}
                                    alt=""
                                    className="flex-grow-0 flex-shrink-0 w-4 h-4 absolute left-[-24px] top-0.5 "
                                />
                            </div>
                            <div className="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
                                <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#4e4c54]">
                                    Exhange rate
                                </p>
                                <p className="flex-grow-0 flex-shrink-0 text-lg font-semibold text-left text-[#2e2b3b]">
                                    {selectedCurrent}
                                </p>
                                <img
                                    src={rate}
                                    alt=""
                                    className="flex-grow-0 flex-shrink-0 w-4 h-4 absolute left-[-24px] "
                                />
                            </div>
                            <div className="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
                                <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#4e4c54]">
                                    Total Payable Amount
                                </p>
                                <p className="flex-grow-0 flex-shrink-0 text-lg font-semibold text-left text-[#2e2b3b]">
                                    {findedValue}
                                </p>
                                <img
                                    src={equal}
                                    alt=""
                                    className="flex-grow-0  flex-shrink-0 w-4 h-4 absolute left-[-24px] "
                                />
                            </div>
                            {/* <div className="h-20 absolute bg-[#9595cd] w-[1px] left- bottom-[41%]" /> */}
                        </div>
                    </div>
                    <div className=" w-full">
                        <Selection
                            currencyArr={RecievingCountries}
                            readOnly={true}
                            handleChange={handleOppositeChange}
                            selectedOption={oppositeOption}
                            enteredValue={(enteredValue * conversion?.conversion_rate).toFixed(2)}
                            title="Recipient gets"
                            istwo={true}
                        />
                    </div>
                    {button && (
                        <button
                            onClick={handleSubmit}
                            type="submit"
                            className="w-full items-center justify-center space-x-3 transform-gpu hover:bg-white rounded-lg text-white bg-gradient-to-r bg-[#554AE4] text-secondary  hover:from-[#554AE4] hover:to-[#554AE4] block text-center active:outline-none px-3 lg:px-4 xl:px-8 font-medium lg:text-lg py-3   focus:outline-none focus:ring focus:border-indigo-500 focus:ring-indigo-500/50"
                        >
                            <span className="text-2xl text-[#ffffff]">Get started </span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TransferForm;
