import * as React from "react";
import Nav from "../layouts/Nav";
import Footer from "./Footer";
import { ChevronRight } from "lucide-react";
const FAQItem = ({ question, description, isOpen, onToggle }) => (
    <div className="flex flex-col mt-4 max-md:max-w-full">
        {" "}
        <div
            className="flex gap-5 cursor-pointer justify-between items-start max-md:flex-wrap max-md:max-w-full"
            onClick={onToggle}
        >
            {" "}
            <div className="max-md:max-w-full">{question}</div>{" "}
            <ChevronRight
                alt="Expand icon"
                className="shrink-0 w-6 aspect-square cursor-pointer transition-transform duration-300 ease-in-out"
                style={{ transform: isOpen ? "rotate(90deg)" : "rotate(0deg)" }}
            />{" "}
        </div>{" "}
        {isOpen && (
            <div className="mt-2 text-base leading-6 text-gray-600 max-md:max-w-full transition-all duration-300 ease-in-out">
                {" "}
                {description}{" "}
            </div>
        )}{" "}
        <div className="shrink-0 mt-6 h-px border border-solid bg-[#E2E8F0] border-[#E2E8F0] max-md:max-w-full" />{" "}
    </div>
);
const FAQList = ({ category }) => {
    const [openIndex, setOpenIndex] = React.useState(null);
    const questions = {
        All: [
            {
                question: "How do I create an account?",
                description:
                    "To create an account, visit our website or download our mobile app, then click on the 'Sign Up' button and follow the prompts to provide your personal information."
            },
            {
                question: "Is my personal information secure?",
                description:
                    "Yes, we take the security of your personal information very seriously. We use encryption and other security measures to protect your data."
            },
            {
                question: "What currencies can I send and receive?",
                description:
                    "We support a wide range of currencies for sending and receiving money. You can check the list of supported currencies on our platform."
            },
            {
                question: "How long does it take for a transfer to arrive?",
                description:
                    "Transfer times vary depending on factors such as the destination country and the payment method. In most cases, transfers are completed within 1-3 business days."
            },
            {
                question: "What are the fees for sending money?",
                description:
                    "Our fees vary depending on factors such as the amount being sent, the destination country, and the chosen delivery method. You can view our fee schedule on our website."
            },
            {
                question: "Can I cancel a transfer?",
                description:
                    "Once a transfer has been initiated, it may not be possible to cancel it. However, you can contact our customer support team for assistance."
            },
            {
                question: "Is there a limit on how much I can send?",
                description:
                    "Yes, there are limits on how much you can send per transaction and within a certain time period. These limits vary depending on factors such as your account verification level."
            },
            {
                question: "How do I track the status of my transfer?",
                description:
                    "You can track the status of your transfer by logging into your account and viewing the transaction history. You will also receive email notifications at key stages of the transfer process."
            },
            {
                question: "What happens if I enter the wrong recipient information?",
                description:
                    "It's important to double-check the recipient's information before initiating a transfer. If you enter incorrect information, contact our customer support team as soon as possible for assistance."
            },
            {
                question: "Is customer support available 24/7?",
                description:
                    "Yes, our customer support team is available 24 hours a day, 7 days a week to assist you with any questions or issues you may have."
            }
        ],
        "Signing Up": [
            {
                question: "How do I sign up for an account?",
                description:
                    "To sign up for an account, click the 'Sign Up' button on our website or mobile app and follow the prompts to provide your personal information."
            },
            {
                question: "What information do I need to provide to sign up?",
                description:
                    "You will need to provide your full name, email address, phone number, and create a password to sign up for an account."
            },
            {
                question: "Is account verification required?",
                description:
                    "Yes, for security purposes, we may require you to verify your identity before you can send or receive money. This typically involves providing additional documentation."
            },
            {
                question: "Can I sign up with social media accounts?",
                description:
                    "Currently, we do not offer the option to sign up using social media accounts. You will need to create an account using your email address."
            },
            {
                question: "Are there any age restrictions for signing up?",
                description:
                    "Yes, you must be at least 18 years old to sign up for an account with us."
            },
            {
                question: "Can I have multiple accounts?",
                description:
                    "No, each individual is only allowed to have one account with us. Having multiple accounts may result in your accounts being suspended or closed."
            },
            {
                question: "What should I do if I encounter issues during sign-up?",
                description:
                    "If you encounter any issues during the sign-up process, please contact our customer support team for assistance."
            },
            {
                question: "Is there a fee for signing up?",
                description:
                    "No, there is no fee for signing up for an account with us. It's completely free."
            },
            {
                question: "Can I sign up for a business account?",
                description:
                    "Yes, we offer business accounts for companies and organizations. Please contact our business team for more information."
            },
            {
                question: "How long does it take to create an account?",
                description:
                    "Creating an account usually takes just a few minutes. Once you've provided all the required information, your account will be created instantly."
            }
        ],
        "Sending Money": [
            {
                question: "How do I send money to someone?",
                description:
                    "To send money, log in to your account, click on the 'Send Money' tab, enter the recipient's information and the amount you wish to send, then confirm the transaction."
            },
            {
                question: "What information do I need to send money?",
                description:
                    "You will need the recipient's full name, email address, and/or phone number, as well as the amount you wish to send."
            },
            {
                question: "Are there any fees for sending money?",
                description:
                    "Yes, we charge a fee for sending money. The fee varies depending on factors such as the amount being sent, the destination country, and the chosen delivery method."
            },
            {
                question: "What payment methods can I use to send money?",
                description:
                    "You can send money using bank transfers, debit cards, credit cards, and other payment methods available on our platform."
            },
            {
                question: "Is there a limit on how much money I can send?",
                description:
                    "Yes, there are limits on how much you can send per transaction and within a certain time period. These limits vary depending on factors such as your account verification level."
            },
            {
                question: "Can I send money internationally?",
                description:
                    "Yes, you can send money internationally using our platform. We support transfers to many countries around the world."
            },
            {
                question: "How long does it take for the recipient to receive the money?",
                description:
                    "Transfer times vary depending on factors such as the destination country and the chosen delivery method. In most cases, transfers are completed within 1-3 business days."
            },
            {
                question: "Is it safe to send money through your platform?",
                description:
                    "Yes, we take the security of your money and personal information very seriously. We use encryption and other security measures to protect your transactions."
            },
            {
                question: "Can I cancel a money transfer after it's been initiated?",
                description:
                    "Once a money transfer has been initiated, it may not be possible to cancel it. Please review all transaction details carefully before confirming the transfer."
            },
            {
                question: "How do I know if my money transfer was successful?",
                description:
                    "You will receive a confirmation email and/or notification in your account dashboard once your money transfer has been successfully processed."
            }
        ],
        "Receiving Money": [
            {
                question: "How do I receive money sent to me?",
                description:
                    "To receive money, you will need to provide the sender with your full name, email address, and/or phone number associated with your account."
            },
            {
                question: "Is there a fee for receiving money?",
                description:
                    "No, there is typically no fee for receiving money. However, the sender may incur fees when sending the money."
            },
            {
                question: "How long does it take to receive money?",
                description:
                    "Transfer times vary depending on factors such as the sender's location and the chosen delivery method. In most cases, money transfers are received within 1-3 business days."
            },
            {
                question: "What information do I need to provide to receive money?",
                description:
                    "You will need to provide the sender with your full name, email address, and/or phone number associated with your account."
            },
            {
                question: "Can I receive money internationally?",
                description:
                    "Yes, you can receive money from international senders using our platform. We support transfers from many countries around the world."
            },
            {
                question: "Can I receive money without an account?",
                description:
                    "In most cases, you will need to have an account with us to receive money. However, some transfer methods may allow for cash pickup without an account."
            },
            {
                question: "Is it safe to receive money through your platform?",
                description:
                    "Yes, we take the security of your money and personal information very seriously. We use encryption and other security measures to protect your transactions."
            },
            {
                question: "Do I need to pay taxes on received money?",
                description:
                    "Tax obligations vary depending on factors such as your location and the amount of money received. It's advisable to consult with a tax professional for guidance."
            },
            {
                question: "What should I do if I encounter issues with receiving money?",
                description:
                    "If you encounter any issues with receiving money, please contact our customer support team for assistance."
            },
            {
                question: "Can I receive money on behalf of someone else?",
                description:
                    "In most cases, money transfers are intended for the named recipient only. Receiving money on behalf of someone else may require additional verification and authorization."
            }
        ],
        "Account Management": [
            {
                question: "How do I update my personal information?",
                description:
                    "You can update your personal information by logging into your account and navigating to the profile or settings section. From there, you can edit your details as needed."
            },
            {
                question: "Can I change my password?",
                description:
                    "Yes, you can change your password by logging into your account and navigating to the security or password section. Follow the prompts to update your password."
            },
            {
                question: "How do I close my account?",
                description:
                    "To close your account, please contact our customer support team for assistance. They will guide you through the process and ensure that any remaining funds are handled appropriately."
            },
            {
                question: "Is there a fee for closing my account?",
                description:
                    "No, there is typically no fee for closing your account. However, please be aware of any outstanding transactions or fees that may need to be settled before closing your account."
            },
            {
                question: "Can I reopen a closed account?",
                description:
                    "In most cases, closed accounts cannot be reopened. If you wish to use our services again after closing your account, you will need to create a new account."
            },
            {
                question: "How do I change my email address?",
                description:
                    "You can change your email address by logging into your account and navigating to the profile or settings section. From there, you can edit your email address as needed."
            },
            {
                question: "Can I have multiple accounts?",
                description:
                    "No, each individual is only allowed to have one account with us. Having multiple accounts may result in your accounts being suspended or closed."
            },
            {
                question: "How do I update my contact information?",
                description:
                    "You can update your contact information, such as your phone number or mailing address, by logging into your account and navigating to the profile or settings section."
            },
            {
                question: "Is my account information shared with third parties?",
                description:
                    "We take the privacy and security of your account information very seriously. We do not share your information with third parties except as necessary to provide our services or as required by law."
            },
            {
                question: "Can I change my account currency?",
                description:
                    "In most cases, once you have selected a currency for your account, it cannot be changed. If you need to use a different currency, you may need to create a new account."
            }
        ]
    };

    const handleToggle = index => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <div className="flex flex-col self-stretch pr-6 my-auto text-xl font-medium leading-8 text-[#0F172A] max-md:mt-10 max-md:max-w-full">
            {" "}
            {questions[category].map(({ question, description }, index) => (
                <FAQItem
                    key={index}
                    question={question}
                    description={description}
                    isOpen={openIndex === index}
                    onToggle={() => handleToggle(index)}
                />
            ))}{" "}
        </div>
    );
};
const CategoryButton = ({ label, active, onClick }) => (
    <div
        className={`flex flex-col justify-center px-3 py-0.5 mt-5 shadow-sm rounded-[40px] cursor-pointer ${
            active ? "font-bold text-secondary bg-[#FE5719]" : "text-[#0F172A]"
        }`}
        onClick={onClick}
    >
        {" "}
        <div className="justify-center">{label}</div>{" "}
    </div>
);
const CategoryList = ({ onCategoryChange }) => {
    const [activeCategory, setActiveCategory] = React.useState("All");
    const categories = [{ label: "All" }, { label: "Signing Up" }, { label: "Sending Money" }];
    const handleClick = category => {
        setActiveCategory(category);
        onCategoryChange(category);
    };
    return (
        <div className="flex flex-col mt-16 text-sm leading-7 max-md:mt-10">
            {" "}
            {categories.map(({ label }) => (
                <CategoryButton
                    key={label}
                    label={label}
                    active={activeCategory === label}
                    onClick={() => handleClick(label)}
                />
            ))}{" "}
        </div>
    );
};
function FAQ() {
    const [selectedCategory, setSelectedCategory] = React.useState("All");
    return (
        <>
            <Nav />
            <div className="pt-10 flex flex-col justify-center p-7 bg-white rounded-xl shadow-2xl max-md:px-5">
                <div className="max-md:max-w-full  md:px-10 mt-10">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 ">
                        <aside className="flex flex-col w-[31%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col   items-start pt-12 pr-20 pb-20 pl-6 rounded-xl text-[#0F172A] max-md:px-5 max-md:mt-10">
                                <h2 className="text-3xl text-primary font-medium">FAQ</h2>{" "}
                                <CategoryList onCategoryChange={setSelectedCategory} />{" "}
                            </div>
                        </aside>{" "}
                        <section className="flex mt-10 flex-col ml-5 w-[69%] max-md:ml-0 max-md:w-full">
                            {" "}
                            <FAQList category={selectedCategory} />{" "}
                        </section>{" "}
                    </div>{" "}
                </div>{" "}
            </div>
            <Footer />
        </>
    );
}
export default FAQ;
