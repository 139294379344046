import Canada from "../../src/components/controllers/flags/canada.png";
import Kenya from "../../src/components/controllers/flags/kenya.png";
import England from "../../src/components/controllers/flags/england.png";
import Australia from "../../src/components/controllers/flags/australia.png";
import Norway from "../../src/components/controllers/flags/norwey.png";
import United_States from "../../src/components/controllers/flags/UnitedState.png";
import Austria from "../../src/components/controllers/flags/austria.png";
import Belgium from "../../src/components/controllers/flags/belgium.png";
import Denmark from "../../src/components/controllers/flags/denmark.png";
import Estonia from "../../src/components/controllers/flags/estonia.png";
import Finland from "../../src/components/controllers/flags/finland.png";
import France from "../../src/components/controllers/flags/france.png";
import Germany from "../../src/components/controllers/flags/germany.png";
import Iceland from "../../src/components/controllers/flags/iceland.png";
import Ireland from "../../src/components/controllers/flags/ireland.png";
import Italy from "../../src/components/controllers/flags/italy.png";
import Latvia from "../../src/components/controllers/flags/latvia.png";
import Lithuania from "../../src/components/controllers/flags/lithuania.png";
import Netherlands from "../../src/components/controllers/flags/netherlands.png";
import Poland from "../../src/components/controllers/flags/poland.png";
import Portugal from "../../src/components/controllers/flags/portugal.png";
import Spain from "../../src/components/controllers/flags/spain.png";
import Sweden from "../../src/components/controllers/flags/sweden.png";
import Somalia from "../../src/components/controllers/flags/somalia.png";
import Uganda from "../../src/components/controllers/flags/uganda.png";
import Pakistan from "../../src/components/controllers/flags/pakistan.png";
import India from "../../src/components/controllers/flags/india.png";

export const Flags = {
    australia: Australia,
    austria: Austria,
    belgium: Belgium,
    canada: Canada,
    england: England,
    kenya: Kenya,
    norway: Norway,
    united_states: United_States,
    denmark: Denmark,
    estonia: Estonia,
    finland: Finland,
    france: France,
    germany: Germany,
    iceland: Iceland,
    ireland: Ireland,
    italy: Italy,
    latvia: Latvia,
    lithuania: Lithuania,
    netherlands: Netherlands,
    poland: Poland,
    portugal: Portugal,
    spain: Spain,
    sweden: Sweden,
    somalia: Somalia,
    uganda: Uganda,
    pakistan: Pakistan,
    india: India
};
