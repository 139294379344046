import React, { useState, useEffect } from "react";
import "./PageTwo.css";
import { useVeriffCheckStatusQuery, useVeriffSessionQuery } from "../../store/Veriff";
import PaymentCard from "../controllers/PaymentCard";
import AnimatedSVG from "../../helpers/Animat";

const PageTwo = ({ onClick }) => {
    const [check, setCheck] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const { data, error, isLoading } = useVeriffSessionQuery();
    const {
        data: userData,
        error: statusError,
        isLoading: statusLoading,
        refetch
    } = useVeriffCheckStatusQuery();

    console.log("User Data : ", data);

    const handleVerification = () => {
        const url = data?.data?.verification?.url;
        window.open(url, "_blank");
        setCheck(true);
    };

    const handleCheckStatus = () => {
        try {
            setShowLoader(true); // Show the loader

            setTimeout(() => {
                refetch();
                setShowLoader(false); // Hide the loader after 1 second
            }, 1000); // 1 second delay before refetching the data
        } catch (error) {
            console.log("Error : ", error);
            setShowLoader(false); // In case of error, hide the loader
        }
    };

    return (
        <PaymentCard>
            {isLoading ? (
                <AnimatedSVG className="w-full h-8 text-white" />
            ) : (
                <div className="bg-white p-6 rounded-lg shadow-md w-[400px]">
                    <div className="flex justify-center mb-4">
                        {/* Logo Placeholder */}
                        <img
                            src="/images/veriff-logo.svg" // Replace with the actual path to your logo
                            alt="Logo"
                            className="h-10"
                        />
                    </div>

                    <p className="text-black text-xl text-bold mt-36">
                        We need to verify your identity to make Faremit more secure for you
                    </p>

                    {showLoader ? (
                        <AnimatedSVG className="w-full h-8 text-white mt-36 mb-36" />
                    ) : (
                        check &&
                        (userData?.status?.verificationStatus === "verified" ? (
                            <div className="px-10 mt-16 mb-16 py-2 rounded bg-[#28a74550] w-[100px] m-auto self-center">
                                <p className="text-[#28a745]">
                                    {`${userData?.status?.verificationStatus}`}
                                </p>
                            </div>
                        ) : (
                            userData?.status?.verificationStatus === "pending" && (
                                <p
                                    className={`mt-36 mb-36 rounded-full text-sm font-semibold text-red-600 bg-red-100`}
                                >
                                    {`${userData?.status?.verificationStatus}`}
                                </p>
                            )
                        ))
                    )}

                    {!check ? (
                        <button
                            onClick={handleVerification}
                            className="w-full bg-[#4f46e5] mt-10 text-[#ffffff] py-3 rounded mb-3 hover:bg-purple-700 hover:text-[#ffffff]"
                        >
                            Start Verification
                        </button>
                    ) : (
                        <button
                            onClick={
                                userData?.status?.verificationStatus === "verified"
                                    ? onClick
                                    : handleCheckStatus
                            }
                            className={`w-full bg-[#4f46e5] mt-10 text-[#ffffff] py-3 rounded mb-3 hover:bg-purple-700 hover:text-[#ffffff] ${
                                userData?.status?.verificationStatus === "verified"
                                    ? `bg-[#28a745]` // Style for verified status
                                    : `bg-[#4f46e5] `
                            }`}
                        >
                            {`${
                                userData?.status?.verificationStatus === "verified"
                                    ? "Next"
                                    : "Check Status"
                            }`}
                        </button>
                    )}
                </div>
            )}
        </PaymentCard>
    );
};

export default PageTwo;
