import PaymentCard from "../controllers/PaymentCard";

const ReviewPage = ({ selectedRecipient, onButtonClick, amount }) => {
    const InfoCard = ({ title, text }) => (
        <div>
            <p className="text-black text-left font-500  mb-2">{title}</p>
            <p className="text-black text-left font-bold font-500  mb-6">{text}</p>
        </div>
    );

    return (
        <PaymentCard>
            <div className="bg-white rounded-2xl shadow-md p-8 w-[400px]">
                <h2 className="text-2xl flex align-start font-bold mb-3">Transfer details</h2>
                <div className="flex flex-row justify-between">
                    <InfoCard title={`You're sending :`} text={`£${amount} EUR`} />
                    <InfoCard title={`Fee :`} text={`2.99 EUR`} />
                </div>
                <div className="flex flex-row justify-between">
                    <InfoCard title={`Total amount to be paid :`} text={`202.99 EUR`} />
                    <InfoCard title={`Recipient will get :`} text={`200,000 NGN`} />
                </div>
                <InfoCard title={`Payment made via :`} text={`Bank Transfer`} />
                <div className="mb-6">
                    <h2 className="text-2xl flex align-start font-bold mb-3">Recipient details</h2>
                    <ul className="list-none text-black-700 text-left list-disc pl-5 font-medium">
                        <li>{`Payee Name: ${selectedRecipient?.firstName}`}</li>
                        <li>
                            {`Account Type: ${selectedRecipient?.bankAccounts[0]?.accountType}`}
                        </li>
                        <li>{`Account Number: ${selectedRecipient?.bankAccounts[0]?.IBAN}`}</li>
                    </ul>
                </div>
                <button
                    onClick={onButtonClick}
                    className="w-full bg-[#4f46e5] text-[#ffffff] py-3 rounded mb-3 hover:bg-purple-700 hover:text-[#ffffff]"
                >
                    Send
                </button>
            </div>
        </PaymentCard>
    );
};

export default ReviewPage;
