// MultiStep.js
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import tachyons from "tachyons";
import MultiStepProgressBar from "../components/MultiStepProgressBar/MultiStepProgressBar";
import PageOne from "../components/Recipient/PageOne";
import PageTwo from "../components/Kyc/PageTwo";
import Nav from "../layouts/Nav";
import PageThree from "../components/Waiting/PageThree";
import { useNavigate } from "react-router-dom";
import ConsentPage from "../components/YapilyPages/ConsentPage";
import LoginBankPage from "../components/YapilyPages/LoginBankPage";
import AnimatedSVG from "../helpers/Animat";
import {
    useYapilyAuthorizationConsentMutation,
    useYapilyCreateUserQuery,
    useYapilyInitiatePaymentMutation
} from "../store/Yapily";
import QRPage from "../components/YapilyPages/QRPage";
import { useVeriffSessionQuery } from "../store/Veriff";
import { io } from "socket.io-client";
import ReviewPage from "../components/YapilyPages/ReviewPage";
import SuccessPage from "../components/YapilyPages/SuccessPage";
import { toast } from "../helpers";

const socket = io("https://api.faremit.com"); // Connect to the server

function MultiStep() {
    const amount = useSelector(state => state.transfer.amount);
    const recipient = useSelector(state => state.transfer.recipient);
    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();
    const selectedRecipient = useSelector(state => state.transfer.recipient);
    const [YapilyAuthorizationConsent, { isLoading, isSuccess, isError }] =
        useYapilyAuthorizationConsentMutation();
    const [
        YapilyInitiatePayment,
        { isLoading: paymentLoading, isSuccess: paymentSuccess, isError: paymentError }
    ] = useYapilyInitiatePaymentMutation();
    const {
        data: createUser,
        isLoading: createLoading,
        Error: createError
    } = useYapilyCreateUserQuery();
    const [qrData, setQRData] = useState();
    const [consentData, setConsentData] = useState();
    const [successData, setSuccessData] = useState();
    const { data, error, isLoading: verifLoading } = useVeriffSessionQuery();
    const [loading, setLoading] = useState(false);

    console.log("Create User : ", createUser);
    console.log("Amount entered : ", amount);
    const bankAccount = recipient?.bankAccounts?.find(bank => bank);

    console.log("Accont Banks Data : ", bankAccount?.accountType);
    useEffect(() => {
        // Listen for messages from the server
        socket.on("connect", () => {
            console.log("Connected to server");
            // Emit an event to the server
            socket.emit("saif", "hor suna saleemo tu kutha kutha");
        });

        socket.on("authorization-callback", message => {
            console.log("Socket message : ", message);
            setConsentData(message);
        });

        // socket.emit("saif", "Ki hal chal ay");

        // Clean up the connection when the component unmounts
        return () => {
            socket.disconnect();
        };
    }, []);

    const paymentDetails = {
        institutionId: "modelo-sandbox",
        amount: `${amount || ""}`,
        currency: "EUR",
        payee: {
            name: `${recipient?.firstName}`,
            address: {
                country: "GB"
            },
            accountIdentifications: [
                {
                    type: `${bankAccount?.accountType}`,
                    identification: `${bankAccount?.IBAN}`
                }
            ]
        }
    };

    const handleSubmit = async () => {
        try {
            handleNext();
            const response = await YapilyAuthorizationConsent(paymentDetails).unwrap();
            console.log("Payment ID res:", response);
            setQRData(response);
            localStorage.setItem("paymentIdempotencyId", response?.paymentIdempotencyId);
        } catch (error) {
            console.error("Payment failed:", error);
        }
    };
    const findConsentToken = (consents, institutionId) => {
        const consent = consents?.find(consent => consent.institution === institutionId);
        return consent ? consent.consentToken : null;
    };
    const instID = "modelo-sandbox";
    const paymentToken = findConsentToken(consentData?.yapilyConsents, instID);

    const paymentBody = {
        consentToken: `${paymentToken || ""}`,
        paymentIdempotencyId: qrData?.paymentIdempotencyId,
        amount: `${amount || ""}`,

        currency: "EUR",
        payee: {
            name: `${recipient?.firstName}`,
            address: {
                country: "GB"
            },
            accountIdentifications: [
                {
                    type: `${bankAccount?.accountType}`,
                    identification: `${bankAccount?.IBAN}`
                }
            ]
        }
    };

    const handlePaymentInitiation = async () => {
        try {
            handleNext();
            const response = await YapilyInitiatePayment(paymentBody).unwrap();
            console.log("Payment successful:", response);
            setSuccessData(response);
        } catch (error) {
            console.error("Payment failed:", error);
        }
    };
    console.log("Selected Recipient : ", selectedRecipient);

    const steps = [
        { label: "Amount & Recipient", step: 1 },
        { label: "Complete KYC", step: 2 },
        { label: "Choose your bank", step: 3 },
        { label: "Consent page", step: 4 },
        { label: "Login to a bank", step: 5 },
        { label: "Review Details", step: 6 },
        { label: "Payment initiated", step: 7 }
        // { label: "Review Details", step: 7 },
        // { label: "Confirmation Code", step: 8 },
        // { label: "IBAN check", step: 6 },
        // { label: "Choosing SCA", step: 7 },
        // { label: "Confirmation Code", step: 8 },
        // { label: "Payment initiated", step: 9 }
    ];

    const handleNext = values => {
        if (currentStep === 1) {
            if (selectedRecipient) {
                if (data?.status === "verified") {
                    setCurrentStep(currentStep + 2);
                } else {
                    setCurrentStep(currentStep + 1);
                }
            } else {
                toast("Failed", "Please select a recipient to continue");
            }
        } else if (currentStep === 2) {
            setCurrentStep(currentStep + 1);
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrev = () => {
        if (currentStep === 1) {
            navigate(-1);
        } else if (currentStep === 3) {
            if (data?.status === "verified") {
                setCurrentStep(currentStep - 2);
            } else {
                setCurrentStep(currentStep - 1);
            }
        } else {
            setCurrentStep(currentStep - 1);
        }
    };

    function ProgressStep({ label, active, step }) {
        return (
            <div className="flex flex-col">
                <div>{label}</div>
                <div
                    className={`shrink-0 mt-4 h-1.5 rounded-md ${
                        currentStep === step ? "bg-[#4f46e5]" : "bg-[#d4d4d8]"
                    }`}
                />
            </div>
        );
    }

    return (
        <>
            <Nav />
            <div className="flex flex-col">
                <div className="text-center md:mt-5 py-14 flex items-center justify-center">
                    <div className="mt-10 shadow-lg  w-auto ">
                        <nav className="flex justify-center items-center px-16 py-5 text-sm font-medium tracking-wide leading-5 shadow-sm bg-[#f8fafc] text-[#0f172a] max-md:px-5">
                            <img
                                onClick={handlePrev}
                                height={24}
                                width={24}
                                src="/images/back.png"
                                className="mr-[40px] cursor-pointer "
                            />
                            <ol className="flex gap-4 gap-x-16 max-md:flex-wrap">
                                {steps.map((step, index) => (
                                    <li key={index}>
                                        <ProgressStep
                                            label={step?.label}
                                            active={step?.active}
                                            step={step?.step}
                                        />
                                    </li>
                                ))}
                            </ol>
                        </nav>
                        <div className="mt-10">
                            {currentStep === 1 ? (
                                <PageOne onButtonClick={handleNext} />
                            ) : currentStep === 2 ? (
                                <PageTwo onClick={handleNext} />
                            ) : currentStep === 3 ? (
                                <PageThree onButtonClick={handleNext} />
                            ) : currentStep === 4 ? (
                                <ConsentPage
                                    amount={amount}
                                    selectedRecipient={selectedRecipient}
                                    onButtonClick={handleSubmit}
                                />
                            ) : currentStep === 5 ? (
                                isLoading ? (
                                    <AnimatedSVG className="w-full h-8 text-white" />
                                ) : (
                                    <QRPage
                                        consentData={consentData}
                                        onButtonClick={handleNext}
                                        data={qrData}
                                    />
                                )
                            ) : currentStep === 6 ? (
                                <ReviewPage
                                    amount={amount}
                                    onButtonClick={handlePaymentInitiation}
                                    selectedRecipient={selectedRecipient}
                                />
                            ) : currentStep === 7 ? (
                                paymentLoading ? (
                                    <AnimatedSVG className="w-full h-8 text-white" />
                                ) : (
                                    <SuccessPage />
                                )
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MultiStep;
