import { Flags } from "./flags";

const SendingCountries = [
    { currency: "EUR", image: Flags.austria, country: "Austria" },
    { currency: "EUR", image: Flags.belgium, country: "Belgium" },
    { currency: "CAD", image: Flags.canada, country: "Canada" },
    { currency: "EUR", image: Flags.denmark, country: "Denmark" },
    { currency: "EUR", image: Flags.estonia, country: "Estonia" },
    { currency: "EUR", image: Flags.finland, country: "Finland" },
    { currency: "EUR", image: Flags.france, country: "France" },
    { currency: "EUR", image: Flags.germany, country: "Germany" },
    { currency: "EUR", image: Flags.iceland, country: "Iceland" },
    { currency: "EUR", image: Flags.ireland, country: "Ireland" },
    { currency: "EUR", image: Flags.italy, country: "Italy" },
    { currency: "EUR", image: Flags.latvia, country: "Latvia" },
    { currency: "EUR", image: Flags.lithuania, country: "Lithuania" },
    { currency: "EUR", image: Flags.netherlands, country: "Netherlands" },
    { currency: "NOK", image: Flags.norway, country: "Norway" },
    { currency: "EUR", image: Flags.poland, country: "Poland" },
    { currency: "EUR", image: Flags.portugal, country: "Portugal" },
    { currency: "EUR", image: Flags.spain, country: "Spain" },
    { currency: "EUR", image: Flags.sweden, country: "Sweden" },
    { currency: "GBP", image: Flags.england, country: "United Kingdom" },
    { currency: "USD", image: Flags.united_states, country: "United States" }
];

const RecievingCountries = [
    { currency: "AUD", image: Flags.australia, country: "Australia" },
    { currency: "EUR", image: Flags.austria, country: "Austria" },
    { currency: "EUR", image: Flags.belgium, country: "Belgium" },
    { currency: "CAD", image: Flags.canada, country: "Canada" },
    { currency: "EUR", image: Flags.denmark, country: "Denmark" },
    { currency: "EUR", image: Flags.estonia, country: "Estonia" },
    { currency: "EUR", image: Flags.finland, country: "Finland" },
    { currency: "EUR", image: Flags.france, country: "France" },
    { currency: "EUR", image: Flags.germany, country: "Germany" },
    { currency: "EUR", image: Flags.iceland, country: "Iceland" },
    { currency: "INR", image: Flags.india, country: "India" },
    { currency: "EUR", image: Flags.ireland, country: "Ireland" },
    { currency: "EUR", image: Flags.italy, country: "Italy" },
    { currency: "KES", image: Flags.kenya, country: "Kenya" },
    { currency: "EUR", image: Flags.latvia, country: "Latvia" },
    { currency: "EUR", image: Flags.lithuania, country: "Lithuania" },
    { currency: "EUR", image: Flags.netherlands, country: "Netherlands" },
    { currency: "NOK", image: Flags.norway, country: "Norway" },
    { currency: "PKR", image: Flags.pakistan, country: "Pakistan" },
    { currency: "EUR", image: Flags.poland, country: "Poland" },
    { currency: "EUR", image: Flags.portugal, country: "Portugal" },
    { currency: "EUR", image: Flags.spain, country: "Spain" },
    { currency: "EUR", image: Flags.sweden, country: "Sweden" },
    { currency: "SOS", image: Flags.somalia, country: "Somalia" },
    { currency: "UGX", image: Flags.uganda, country: "Uganda" },
    { currency: "GBP", image: Flags.england, country: "United Kingdom" }
];

const countries = [
    { code: "+61", flag: Flags.australia, name: "Australia" },
    { code: "+43", flag: Flags.austria, name: "Austria" },
    { code: "+32", flag: Flags.belgium, name: "Belgium" },
    { code: "+1", flag: Flags.canada, name: "Canada" },
    { code: "+45", flag: Flags.denmark, name: "Denmark" },
    { code: "+372", flag: Flags.estonia, name: "Estonia" },
    { code: "+358", flag: Flags.finland, name: "Finland" },
    { code: "+33", flag: Flags.france, name: "France" },
    { code: "+49", flag: Flags.germany, name: "Germany" },
    { code: "+354", flag: Flags.iceland, name: "Iceland" },
    { code: "+353", flag: Flags.ireland, name: "Ireland" },
    { code: "+39", flag: Flags.italy, name: "Italy" },
    { code: "+254", flag: Flags.kenya, name: "Kenya" },
    { code: "+371", flag: Flags.latvia, name: "Latvia" },
    { code: "+370", flag: Flags.lithuania, name: "Lithuania" },
    { code: "+31", flag: Flags.netherlands, name: "Netherlands" },
    { code: "+47", flag: Flags.norway, name: "Norway" },
    { code: "+48", flag: Flags.poland, name: "Poland" },
    { code: "+351", flag: Flags.portugal, name: "Portugal" },
    { code: "+34", flag: Flags.spain, name: "Spain" },
    { code: "+46", flag: Flags.sweden, name: "Sweden" },
    { code: "+252", flag: Flags.somalia, name: "Somalia" },
    { code: "+256", flag: Flags.uganda, name: "Uganda" },
    { code: "+44", flag: Flags.england, name: "United Kingdom" }
];

export { SendingCountries, RecievingCountries, countries };
