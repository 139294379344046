import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollPage";
import { Auth, Guest } from "./middlewares";
import ContactUs from "./pages/Contact";
import Home from "./pages/Home";
import USAPatriot from "./pages/Legal/ USAPatriot";
import Consent from "./pages/Legal/Consent";
import Privacy from "./pages/Legal/Privacy";
import MultiStep from "./pages/MultiStep";
import Login from "./pages/Login";
import RegistrationForm from "./pages/Register";
import FAQ from "./pages/Faq";
import TemplateReact from "./components/dashboard/templateReact";

const App = () => {
    useEffect(() => {
        // Function to display warning message when the console is opened
        function displayConsoleWarning() {
            console.warn("Stop!");
            console.warn(
                "This is a browser feature intended for developers. If someone told you to copy-paste something here to enable a Facebook feature or 'hack' someone's account, it is a scam and will give them access to your Facebook account."
            );
        }

        // Add event listener to detect opening of console
        window.addEventListener("keydown", function (event) {
            if (event.keyCode === 123) {
                displayConsoleWarning();
                event.preventDefault();
            }
        });

        // Disable right-click context menu to prevent opening console in some browsers
        window.addEventListener("contextmenu", function (event) {
            event.preventDefault();
        });

        // Cleanup function to remove event listeners
        return () => {
            window.removeEventListener("keydown", displayConsoleWarning);
            window.removeEventListener("contextmenu", function (event) {
                event.preventDefault();
            });
        };
    }, []);
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/privacy" element={<Privacy />} />
                <Route
                    path="/login"
                    element={
                        <Guest>
                            <Login />
                        </Guest>
                    }
                />
                <Route
                    path="/faq"
                    element={
                        <Guest>
                            <FAQ />
                        </Guest>
                    }
                />
                <Route
                    path="/signup"
                    element={
                        <Guest>
                            <RegistrationForm />
                        </Guest>
                    }
                />
                <Route path="/Consent" element={<Consent />} />
                <Route path="/law" element={<USAPatriot />} />

                <Route path="/contact" element={<ContactUs />} />
                <Route path="/dashboard" element={<TemplateReact />} />

                <Route
                    path="/send"
                    element={
                        <Auth>
                            <MultiStep />
                        </Auth>
                    }
                />
                <Route path="/*" element={<Home />} />
            </Routes>
        </Router>
    );
};

export default App;
