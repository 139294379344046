import phoneVideo from "./phone.mp4";

function AppDownload() {
    return (
        <div className="flex  flex-col md:flex-row mb-5 items-center -mt-10 justify-between max-w-6xl mx-auto">
            <video className="w-full md:w-1/2" autoPlay loop muted>
                <source src={phoneVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div>
                <h1 className="font-neue-haas-grotesk-display-pro md:text-5xl w-max text-slate-900 text-opacity-100 leading-none font-medium">
                    Low fees and the <br />
                    Best Rates For You
                </h1>

                <div className="mt- ml-0.5 font-neue-haas-grotesk-display-pro text-xl md:w-[603px] text-stone-500 text-opacity-100 leading-9 tracking-wider font-normal">
                    Download our app for free to send money online in minutes to over 130 other
                    countries. Track your payments and view your transfer history from anywhere.
                </div>
                <div className="flex items-center space-x-1.5">
                    <button className="mt-8  sm:flex flex justify-center border  items-center rounded-md w-36 h-12 shadow-sm bg-white border-black">
                        <div className="flex space-x-3  justify-center items-center w-24 h-4">
                            <img src="/images/apple.png" alt="" className="w-4 h-4" />
                            <div className="font-inter text-base whitespace-nowrap text-gray-800  text-opacity-100 leading-7 font-bold">
                                App store
                            </div>
                        </div>
                    </button>
                    <button className="mt-8  sm:flex flex justify-center border  items-center rounded-md w-36 h-12 shadow-sm bg-black text-white border-black">
                        <div className="flex space-x-3  justify-center items-center w-24 h-4">
                            <img src="/images/playstore.png" alt="" className="w-4 h-4" />
                            <div className="font-inter text-base whitespace-nowrap   text-opacity-100 leading-7 font-bold text-secondary">
                                App store
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AppDownload;
