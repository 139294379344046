import { Link } from "react-router-dom";
import PaymentCard from "../controllers/PaymentCard";

const SuccessPage = ({ onButtonClick }) => {
    return (
        <PaymentCard>
            <div className="bg-white p-6 rounded-lg shadow-md w-[400px]">
                <h2 className="text-3xl font-semibold mb-10">Payment Confirmed</h2>
                <div className="flex justify-center mb-4">
                    {/* Logo Placeholder */}
                    <img
                        src="/images/success.png" // Replace with the actual path to your logo
                        alt="Logo"
                        className="h-36"
                    />
                </div>

                <p className="text-black px-20 text-sm mb-4 mt-10">
                    Your transfer to Sinead Bernadette O’Connor was succesful. We’ll send you an
                    email with your receipt and transaction details shortly.
                </p>
                <Link to={"/dashboard"}>
                    <button
                        onClick={onButtonClick}
                        className="w-full bg-[#4f46e5] mt-10 text-[#ffffff] py-3 rounded mb-3 hover:bg-purple-700 hover:text-[#ffffff]"
                    >
                        Continue
                    </button>
                </Link>
            </div>
        </PaymentCard>
    );
};

export default SuccessPage;
