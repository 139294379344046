import { Rootapi } from "..";

const Yapily = Rootapi.injectEndpoints({
    endpoints: builder => ({
        YapilyCreateUser: builder.query({
            query: () => ({
                url: "yapily/createUser",
                method: "GET"
                // body: data
            })
        }),
        YapilyGetInstitutions: builder.query({
            query: () => ({
                url: "/yapily/getInstitution",
                method: "GET"
                // body: data
            })
        }),
        YapilyAuthorizationConsent: builder.mutation({
            query: data => ({
                url: "yapily/authorizaionConsent",
                method: "POST",
                body: data
            })
        }),
        YapilyInitiatePayment: builder.mutation({
            query: data => ({
                url: "yapily/createPayment",
                method: "POST",
                body: data
            })
        })
    })
});

export const {
    useYapilyCreateUserQuery,
    useYapilyGetInstitutionsQuery,
    useYapilyAuthorizationConsentMutation,
    useYapilyInitiatePaymentMutation
} = Yapily;
