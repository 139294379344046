import { Rootapi } from "..";

const Veriff = Rootapi.injectEndpoints({
    endpoints: builder => ({
        VeriffSession: builder.query({
            query: () => ({
                url: "veriff/createSession",
                method: "GET"
                // body: data
            })
        }),
        VeriffCheckStatus: builder.query({
            query: () => ({
                url: "veriff/checkStatus",
                method: "GET"
                // body: data
            })
        })
    })
});

export const { useVeriffSessionQuery, useVeriffCheckStatusQuery } = Veriff;
