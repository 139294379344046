// MultiStepProgressBar.js
function ProgressStep({ label, active }) {
    return (
        <div className="flex flex-col">
            <div>{label}</div>
            <div
                className={`shrink-0 mt-4 h-1.5 rounded-md ${
                    active ? "bg-[#4f46e5]" : "bg-[#d4d4d8]"
                }`}
            />
        </div>
    );
}

function MultiStepProgressBar({ activeStep }) {
    const steps = [
        { label: "Amount & Recipient", step: 1, active: activeStep >= 1 },
        { label: "Complete KYC", step: 2, active: activeStep >= 2 },
        { label: "Review Transfer Details", step: 3, active: activeStep >= 3 },
        { label: "Make Payment", step: 4, active: activeStep >= 4 }
    ];
    return (
        <nav className="flex justify-center items-center px-16 py-5 text-sm font-medium tracking-wide leading-5 shadow-sm bg-[#f8fafc] text-[#0f172a] max-md:px-5">
            <ol className="flex gap-4 gap-x-16 max-md:flex-wrap">
                {steps.map((step, index) => (
                    <li key={index}>
                        <ProgressStep label={step.label} active={step.active} />
                    </li>
                ))}
            </ol>
        </nav>
    );
}
export default MultiStepProgressBar;
