import React, { useState } from "react";
import { logout, UserData } from "../../helpers";
import { useYapilyGetInstitutionsQuery } from "../../store/Yapily";
import PaymentCard from "../controllers/PaymentCard";

const PageThree = ({ onButtonClick }) => {
    const user = UserData();
    const verificationStatus = user && user.id.verificationStatus;

    // State to manage the search input
    const [searchTerm, setSearchTerm] = useState("");

    // Fetch data using the query
    const { data, error, isLoading } = useYapilyGetInstitutionsQuery();

    // Filter the institutions based on the search input
    const filteredInstitutions =
        data?.data?.filter(institution =>
            institution.fullName.toLowerCase().includes(searchTerm.toLowerCase())
        ) || [];

    if (error?.data?.message) {
        logout();
    }

    return (
        <PaymentCard>
            <div className="bg-white shadow-lg rounded-lg p-6 w-[400px]">
                <h2 className="text-3xl flex align-start font-bold mb-4">Choose your bank</h2>
                <div className="relative mb-6">
                    <input
                        type="text"
                        placeholder="Search all banks"
                        className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)} // Update searchTerm state
                    />
                    <div className="absolute left-3 top-3 text-gray-500">
                        <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-4.35-4.35m0 0A7.5 7.5 0 1110.5 3.5a7.5 7.5 0 016.65 13.15l4.35 4.35z"
                            ></path>
                        </svg>
                    </div>
                </div>

                {isLoading ? (
                    <div className="grid grid-cols-1 gap-6 mt-10 w-full">
                        <div className="card">
                            <div className="flex space-x-4">
                                <div className="h-12 w-12 rounded-full loading"></div>
                                <div className="flex-1">
                                    <div className="h-6 w-36 loading mb-1"></div>
                                    <div className="h-4 mb-1 loading"></div>
                                    <div className="h-4 mb-1 loading"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h3 className="text-sm flex align-start font-semibold text-gray-700 mb-4">
                            Most popular banks
                        </h3>
                        <div className="space-y-4 h-[300px] overflow-y-auto">
                            {filteredInstitutions.length > 0 ? (
                                filteredInstitutions.map(institution => (
                                    <div
                                        onClick={() => onButtonClick(institution)}
                                        key={institution.id}
                                        className="flex items-center hover:text-[#ffffff] justify-between p-2 bg-[#f5f5f5] rounded-lg cursor-pointer hover:bg-[#4f46e5]"
                                    >
                                        <div className="flex text-gray hover:text-white items-center space-x-4">
                                            <img
                                                src={
                                                    institution.media.find(
                                                        media => media.type === "logo"
                                                    )?.source
                                                }
                                                alt={institution.fullName}
                                                className="h-10 w-10 rounded-full object-contain"
                                            />
                                            <span className="text-gray select-none font-medium">
                                                {institution.fullName}
                                            </span>
                                        </div>
                                        <div className="text-gray-400 hover:text-white">
                                            <svg
                                                className="w-5 h-5"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 34 34"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 5l7 7-7 7"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-gray-500">No banks found</p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </PaymentCard>
    );
};

export default PageThree;
